import type { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import type { AbstractControl } from '@angular/forms';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import type { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { FormUtils } from '@shared/utils/form-utils';
import { DialogService } from '../../dialogs';
import type { IsLoading } from '../../interfaces';
import type {
  CommandV2PayloadParameter,
  IAvailableCommandV2TypesCollection,
  ICommandV2Type,
  TreeItem,
} from '../../models';
import {
  callServiceCommandV2Type,
  CommandV2,
  CommandV2Types,
  CommandV2TypeTemplateItemTypes,
  sendMessageCommandV2Type,
} from '../../models';
import { IdValidator, JSONValidator } from '../../validators';
import { first, startWith } from 'rxjs/operators';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { AppService } from '@shared/services';

export interface CommandDialogData {
  projectId: string;
  existingCommandIds: string[];
  availableCommandTypesCollection: IAvailableCommandV2TypesCollection;
  command: CommandV2;
}

export interface CommandDialogResult {
  cancelled: boolean;
  model?: CommandV2;
}

enum DialogMode {
  Command,
  DataPicker,
}

@Component({
  selector: 'app-gamepad-command-dialog',
  templateUrl: './gamepad-command-dialog.component.html',
  styleUrls: ['./gamepad-command-dialog.scss'],
})
export class GamepadCommandDialogComponent implements OnInit, IsLoading {
  public form: UntypedFormGroup;
  public isLoading: boolean = false;
  public availableCommandTypesCollection: IAvailableCommandV2TypesCollection = {};

  public readonly dialogMode = DialogMode;
  public mode$ = new BehaviorSubject(DialogMode.Command);
  public projectId: string;
  public callsign$: Observable<string>;

  private readonly command: CommandV2;

  public get id(): AbstractControl {
    return FormUtils.getControl(this.form, 'id');
  }
  public get description(): AbstractControl {
    return FormUtils.getControl(this.form, 'description');
  }

  private readonly existingCommandIds: string[] = [];

  public constructor(
    public dialogRef: MatDialogRef<GamepadCommandDialogComponent, CommandDialogResult>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) private data: CommandDialogData,
    private appService: AppService,
  ) {
    if (data?.projectId) {
      this.projectId = data.projectId;
    }

    if (data?.existingCommandIds) {
      this.existingCommandIds = data.existingCommandIds;
    }

    if (data?.existingCommandIds) {
      this.availableCommandTypesCollection = data.availableCommandTypesCollection;
    }

    if (data?.command) {
      this.command = data.command;
    }

    this.callsign$ = this.appService.callsignChange.pipe(startWith(this.appService.callsign));
  }

  public get parameters(): UntypedFormArray {
    return this.form && (this.form.get('parameters') as UntypedFormArray);
  }

  public get availableCommandTypes(): ICommandV2Type[] {
    return Object.keys(this.availableCommandTypesCollection).map((availableCommandType) => {
      return this.availableCommandTypesCollection[availableCommandType];
    });
  }

  private static createParamFormGroup(paramId?: string, paramDefaultValue?: string): UntypedFormGroup {
    return new UntypedFormGroup({
      id: new UntypedFormControl(paramId ? paramId : '', [Validators.required]),
      default: new UntypedFormControl(paramDefaultValue ? paramDefaultValue : ''),
    });
  }

  public ngOnInit(): void {
    this.createForm();
  }

  public onSubmit(): void {
    this.form.controls['id'].updateValueAndValidity();

    if (!this.form.valid) {
      this.form.markAsDirty();
      return;
    }

    const model = JSON.parse(JSON.stringify(this.form.value));
    const command = CommandV2.fromModel(model);

    this.dialogRef.close({ cancelled: false, model: command });
  }

  public onCommandTypeChange(event: MatRadioChange): void {
    const newSettingsGroup = this.createSettings(event.value);
    this.form.setControl('settings', newSettingsGroup);
  }

  public onCancel(): void {
    if (!this.isLoading) {
      this.dialogRef.close({ cancelled: true });
    }
  }

  public onAddParam(): void {
    this.parameters.push(GamepadCommandDialogComponent.createParamFormGroup());
  }

  public onRemoveParam(index: number): void {
    this.confirmBeforeDelete('parameter', () => {
      this.parameters.removeAt(index);
    });
  }

  public openDataPicker(): void {
    this.mode$.next(DialogMode.DataPicker);
  }

  public closeDataPicker(): void {
    this.mode$.next(DialogMode.Command);
  }

  onDataExplorerChange(item: TreeItem) {
    const settingsGroup = this.form.get('settings') as UntypedFormGroup;
    if (!settingsGroup) return;

    const targetControl = settingsGroup.get('target');
    if (!targetControl) return;

    targetControl.setValue(item.rootSource, { emitEvent: false });
    this.closeDataPicker();
  }

  private confirmBeforeDelete(topic: string, callback: () => void) {
    const uppercaseTopic = topic.charAt(0).toUpperCase() + topic.slice(1);
    const title = `Delete ${uppercaseTopic}?`;
    const message = `<p>Are you sure you want to delete this ${topic}?</p>`;

    this.dialogService
      .deleteConfirm(title, message)
      .pipe(first())
      .subscribe((confirmed) => {
        if (confirmed) {
          callback();
        }
      });
  }

  private createForm() {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(this.command?.id, [
        Validators.required,
        IdValidator.commandId,
        Validators.maxLength(40),
        IdValidator.noDuplicates(() => this.existingCommandIds),
      ]),

      name: new UntypedFormControl(this.command?.name),
      type: new UntypedFormControl(this.command?.type || CommandV2Types.sendMessage, [Validators.required]),
      parameters: this.createParamFormGroups(this.command?.parameters || {}),
      settings: this.createSettings(this?.form?.get('type')?.value || CommandV2Types.sendMessage),
    });
  }

  private createParamFormGroups(params: { [paramId: string]: CommandV2PayloadParameter }): UntypedFormArray {
    const groups = Object.keys(params).map((paramKey) => {
      return GamepadCommandDialogComponent.createParamFormGroup(paramKey, params[paramKey].default);
    });

    return new UntypedFormArray(groups, []);
  }

  private createSettings(commandType: CommandV2Types): UntypedFormGroup {
    switch (commandType) {
      case CommandV2Types.sendMessage:
        return this.createSettingsFormGroup(sendMessageCommandV2Type);
      case CommandV2Types.callService:
        return this.createSettingsFormGroup(callServiceCommandV2Type);
    }
  }

  private createSettingsFormGroup(commandType: ICommandV2Type): UntypedFormGroup {
    const formGroup = {};
    commandType.templateItems.forEach((templateItem) => {
      let commandTypeDataItemValue: unknown;
      if (this.command?.settings && templateItem.id in this.command.settings) {
        commandTypeDataItemValue = this.command.settings[templateItem.id];
      } else {
        commandTypeDataItemValue = commandType.defaultSettingItemsCollection[templateItem.id];
      }

      const validators = [];

      switch (templateItem.type) {
        case CommandV2TypeTemplateItemTypes.json:
          validators.push(JSONValidator.json);

          try {
            commandTypeDataItemValue = JSON.stringify(commandTypeDataItemValue, undefined, 2);
          } catch (ex) {
            // Continue regardless of error
          }
          break;
        case CommandV2TypeTemplateItemTypes.jsTemplateLiterals:
          // Remove backtick surrounded value for ui to display the pure string
          commandTypeDataItemValue = String(commandTypeDataItemValue).replace(/^`+|`+$/g, '');
          break;
        case CommandV2TypeTemplateItemTypes.number:
          validators.push(Validators.pattern('^[0-9]+$'));
          break;
        default:
          break;
      }

      const formControl = new UntypedFormControl(commandTypeDataItemValue);

      if (templateItem.required) {
        validators.push(Validators.required);
      }

      formControl.setValidators(validators);
      formGroup[templateItem.id] = formControl;
    });

    return new UntypedFormGroup(formGroup);
  }
}
