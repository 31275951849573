import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import type { Subscription } from 'rxjs';
import { DevDebugDialogComponent } from '@shared/components';
import { AuthService, CourierService, DevelopmentService, GotoService, UserService } from '@shared/services';
import { environment } from '@env/environment';
import { InvitationsDialogComponent } from '@shared-modules/invitations-dialog';
import { ESidebarStatus } from '@shared/services/gui/gui-state.service';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() public sidebarStatus: ESidebarStatus;

  public isDevHighlight = false;
  public isDevHideDevelopingContent = false;
  public isHideAdminOnlyContent = false;
  public isHideExperimentalContent = false;
  public isProd = false;
  public isAdmin = false;

  // Delay ensures Courier script has finished loading.
  public courierLoaded$ = this.courierService.courierLoaded$.pipe(delay(200));

  private subs: Subscription[] = [];

  public constructor(
    private goto: GotoService,
    private authService: AuthService,
    private devService: DevelopmentService,
    private dialog: MatDialog,
    private userService: UserService,
    private courierService: CourierService,
  ) {
    this.isDevHideDevelopingContent = this.devService.isHideDevelopingContent;
    this.isDevHighlight = this.devService.isHighlightMode;
    this.isHideAdminOnlyContent = this.devService.isHideAdminOnlyContent;
    this.isHideExperimentalContent = this.devService.isHideExperimentalContent;

    const hideDevSub = this.devService.hideDevelopingContent.subscribe((hide) => {
      this.isDevHideDevelopingContent = hide;
    });

    const highlightSub = this.devService.highlightMode.subscribe((highlight) => {
      this.isDevHighlight = highlight;
    });

    const hideAdminSub = this.devService.hideAdminContent.subscribe((hide) => {
      this.isHideAdminOnlyContent = hide;
    });

    const hideExprSub = this.devService.hideExperimentalContent.subscribe((hide) => {
      this.isHideExperimentalContent = hide;
    });

    this.isProd = this.devService.isProd;

    this.subs.push(hideDevSub);
    this.subs.push(highlightSub);
    this.subs.push(hideAdminSub);
    this.subs.push(hideExprSub);
  }

  public ngOnInit(): void {
    this.isAdmin = this.userService.isAdmin();
  }

  public ngOnDestroy(): void {
    if (this.subs?.length > 0) {
      this.subs.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }

  public onInvitation(): void {
    this.dialog.open(InvitationsDialogComponent, { panelClass: 'invitation-dialog' });
  }

  public onDevDebug(): void {
    this.dialog.open(DevDebugDialogComponent);
  }

  public onSignOut(): void {
    this.authService.logout().subscribe(() => this.goto.logout());
  }

  public onDevShowHide(): void {
    this.devService.isHideDevelopingContent = !this.devService.isHideDevelopingContent;
  }

  public onDevHighlight(): void {
    this.devService.isHighlightMode = !this.devService.isHighlightMode;
  }

  public onDocumentationLink(): void {
    window.open(environment.links.docs, '_blank');
  }

  public onAdminShowHide(): void {
    this.devService.isHideAdminOnlyContent = !this.devService.isHideAdminOnlyContent;
  }

  public onExperimentalShowHide(): void {
    this.devService.isHideExperimentalContent = !this.devService.isHideExperimentalContent;
  }
}
