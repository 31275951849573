import { Output, EventEmitter, Injectable, Directive } from '@angular/core';
import { RocosSdkClientService } from '@shared/services';
import type { Observable } from 'rxjs';
import { from } from 'rxjs';
import { forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import type { Widget } from '../../models';
import {
  WidgetMetric,
  WidgetDonutGauge,
  WidgetLineGraph,
  WidgetTable,
  WidgetVideo,
  ConfigGroupItem,
  ConfigGroupConfig,
  GamepadControl,
  GamepadUxComponent,
  RobotSetting,
  WidgetHTML,
  WidgetImage,
  WidgetOps,
  WidgetCustom,
  GamepadConfig,
  GamepadNewControlAction,
} from '../../models';
import { RocosClientService } from '../rocos-client';
import { controlActionsSampleData } from './command-actions-sample-data';
import type { IAvailableCommandV2TypesCollection } from '../../models/command-v2';
import { AvailableCommandV2Types, CommandV2 } from '../../models/command-v2';
import { ProjectPermissionService } from '../project/project-permission.service';
import { EXPERIMENTAL_FEATURE } from '../../enums';
import { UserService } from '../user/user.service';
import { DevelopmentService } from '../dev';
import { WidgetTableV2 } from '../../models/widget/widget-table-v2';
import { WidgetGamepad } from '../../models/widget/widget-gamepad';
import { WidgetAudio } from '../../models/widget/widget-audio';
import { defaultGridsterOptions } from '@shared-modules/dashboard-v2/services';
import { WidgetButtonGroup } from '../../models/widget/widget-button-group';
import { WidgetWebComponent } from '@shared/models/widget/widget-web-component';

export interface RobotDefinition {
  description: string;
  id: string;
  name: string;
  tags: string[];
}

@Directive()
@Injectable({
  providedIn: 'root',
})
export class RobotDefinitionService {
  @Output() public changeStreamStatus: EventEmitter<any> = new EventEmitter();

  public constructor(
    private rocosClientService: RocosClientService,
    private sdk: RocosSdkClientService,
    private projectPermissionService: ProjectPermissionService,
    private userService: UserService,
    private devService: DevelopmentService,
  ) {}

  public create(projectId: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.robotDefsCreate(projectId, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public update(projectId: string, defId: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.robotDefsUpdateOne(projectId, defId, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public remove(projectId: string, defIds: string[]): Observable<any> {
    return this.rocosClientService.rocosClient.robot.robotDefsRemove(projectId, defIds).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  /*
   * @deprecated Use `list` instead.
   */
  public list$(projectId: string): Observable<any> {
    return from(this.list(projectId));
  }

  public list(projectId: string): Promise<RobotDefinition[]> {
    return this.sdk.client.getProfileService().list(projectId);
  }

  public info(projectId: string, defId: string): Observable<any> {
    return this.rocosClientService.rocosClient.robot.robotDefsGetOne(projectId, defId).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  // add event for robot definition
  public addEventDefinitions(projId: string, defId: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.robotDefAddEventDefinitions(projId, defId, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public getAllEventDefinitions(projId: string, defId: string): Observable<any> {
    return this.rocosClientService.rocosClient.robot.robotDefGetEventDefinitions(projId, defId).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public copy(projectId: string, defId: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.robotDefCopyDef(projectId, defId, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  // ----------------------
  // Dashboards

  public getDashboards(projectId: string, defId: string): Observable<any> {
    return this.rocosClientService.rocosClient.robot.robotDefDashboards(projectId, defId).pipe(
      map((res) => {
        const data = res.data as any;

        const dashboard = data ? data : null;

        if (dashboard?.items) {
          dashboard.items = this.getWidgets(dashboard.items);
        }

        return dashboard;
      }),
    );
  }

  public deleteRobotDashboardsV2(projectId: string, profileId: string, id: string): Observable<any> {
    return this.rocosClientService.rocosClient.robot.deleteRobotDashboardsV2(projectId, profileId, id);
  }

  public deleteDashboardsV2(projectId: string, profileId: string, id: string): Observable<any> {
    return this.rocosClientService.rocosClient.robot.deleteDashboardsV2(projectId, profileId, id);
  }

  public listRobotDashboardsV2(projectId: string, callsign: string): Observable<any> {
    return this.rocosClientService.rocosClient.robot.listRobotDashboardsV2(projectId, callsign).pipe(
      map((res) => {
        const data = res.data as any;

        const dashboard = data ? data : null;

        if (dashboard?.items) {
          dashboard.items = this.getWidgets(dashboard.items);
        }

        return dashboard;
      }),
    );
  }

  public listDashboardsV2(projectId: string, profileId: string): Observable<any> {
    return this.rocosClientService.rocosClient.robot.listDashboardsV2(projectId, profileId).pipe(
      map((res) => {
        const data = res.data as any;

        const dashboard = data ? data : null;

        if (dashboard?.items) {
          dashboard.items = this.getWidgets(dashboard.items);
        }

        return dashboard;
      }),
    );
  }

  public getDashboardsV2(projectId: string, profileId: string, id: string): Observable<any> {
    return this.rocosClientService.rocosClient.robot.robotDefDashboardsV2(projectId, profileId, id).pipe(
      map((res) => {
        const data = res.data as any;

        const dashboard = data ? data : null;

        if (dashboard?.items) {
          dashboard.items = this.getWidgets(dashboard.items);
        }

        return dashboard;
      }),
    );
  }

  public getDashboardBlobV2(projectId: string, profileId: string, dashboardId: string): Observable<any> {
    return this.rocosClientService.rocosClient.robot.robotDefDashboardBlobV2(projectId, profileId, dashboardId).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }
  public getDashboardBlob(projectId: string, defId: string): Observable<any> {
    return this.rocosClientService.rocosClient.robot.robotDefDashboardBlob(projectId, defId).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public getDashboardsWithBlobInfoV2(projectId: string, profileId: string, id: string): Observable<any> {
    return forkJoin([
      this.getDashboardsV2(projectId, profileId, id),
      this.getDashboardBlobV2(projectId, profileId, id),
    ]).pipe(
      map(([dashboardInfo, blobInfo]) => {
        // Replace `externalContent` for each widget that exists in blob.
        if (dashboardInfo?.items) {
          dashboardInfo.items.forEach((item) => {
            if (item?.id && item.value && blobInfo[item.id]) {
              item.value.externalContent = blobInfo[item.id];
            }
          });
        }
        return dashboardInfo;
      }),
    );
  }
  public getDashboardsWithBlobInfo(projectId: string, defId: string): Observable<any> {
    return forkJoin([this.getDashboards(projectId, defId), this.getDashboardBlob(projectId, defId)]).pipe(
      map(([dashboardInfo, blobInfo]) => {
        // Replace `externalContent` for each widget that exists in blob.
        if (dashboardInfo?.items) {
          dashboardInfo.items.forEach((item) => {
            if (item?.id && item.value && blobInfo[item.id]) {
              item.value.externalContent = blobInfo[item.id];
            }
          });
        }
        return dashboardInfo;
      }),
    );
  }

  public createRobotDashboardsV2(projectId: string, callsign: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.createRobotDashboardsV2(projectId, callsign, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public createDashboardsV2(projectId: string, defId: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.createDashboardsV2(projectId, defId, model).pipe(
      map((res) => {
        return res.status;
      }),
    );
  }

  public updateDashboardsV2(projectId: string, defId: string, id: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.robotDefUpdateDashboardsV2(projectId, defId, id, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public updateDashboards(projectId: string, defId: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.robotDefUpdateDashboards(projectId, defId, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public getDashboardForRobotV2(projectId: string, callsign: string, id: string): Observable<any> {
    return this.rocosClientService.rocosClient.robot.getDashboardsV2(projectId, callsign, id).pipe(
      map((res) => {
        const data = res.data as any;

        const dashboard = data ? data : null;

        if (dashboard?.items) {
          dashboard.items = this.getWidgets(dashboard.items);
        }

        return dashboard;
      }),
    );
  }

  public getDashboardBlobForRobotV2(projectId: string, callsign: string, id: string): Observable<any> {
    return this.rocosClientService.rocosClient.robot.getDashboardBlobV2(projectId, callsign, id).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public getDashboardWidthBlobInfoForRobotV2(projectId: string, callsign: string, id: string): Observable<any> {
    return forkJoin([
      this.getDashboardForRobotV2(projectId, callsign, id),
      this.getDashboardBlobForRobotV2(projectId, callsign, id),
    ]).pipe(
      map(([dashboardInfo, blobInfo]) => {
        // Replace `externalContent` for each widget that exists in blob.
        if (dashboardInfo?.items) {
          dashboardInfo.items.forEach((item) => {
            if (item?.id && item.value && blobInfo[item.id]) {
              item.value.externalContent = blobInfo[item.id];
            }
          });
        }
        return dashboardInfo;
      }),
    );
  }

  public updateDashboardForRobotV2(projectId: string, callsign: string, id: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.updateDashboardsV2(projectId, callsign, id, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  // ----------------------
  // Controls
  public getControls(projectId: string, defId: string): Observable<ConfigGroupConfig<GamepadControl>> {
    return this.rocosClientService.rocosClient.robot.robotDefGetCommands(projectId, defId).pipe(
      map((res) => {
        const data = res.data as any;
        if (data) {
          const controlsConfig: ConfigGroupConfig<GamepadControl> = data;

          if (controlsConfig?.items) {
            controlsConfig.items = this.getControlItems(controlsConfig.items);
          }

          return controlsConfig;
        }

        return null;
      }),
    );
  }

  public updateControls(projectId: string, defId: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.robotDefUpdateCommands(projectId, defId, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public getControlsForRobot(projectId: string, callsign: string): Observable<any> {
    return this.rocosClientService.rocosClient.robot.getCommands(projectId, callsign).pipe(
      map((res) => {
        const data = res.data as any;
        if (data) {
          const controlsConfig: ConfigGroupConfig<GamepadControl> = data;

          if (controlsConfig?.items) {
            controlsConfig.items = this.getControlItems(controlsConfig.items);
          }

          return controlsConfig;
        }

        return null;
      }),
    );
  }

  public updateControlsForRobot(projectId: string, callsign: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.updateCommands(projectId, callsign, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  // ----------------------
  // Command V2
  public getCommandsV2(projectId: string, defId: string): Observable<ConfigGroupConfig<CommandV2>> {
    return this.rocosClientService.rocosClient.robot.robotDefGetCommandsV2(projectId, defId).pipe(
      map((res: any) => {
        const data = res.data as any;
        if (data) {
          const commandsConfig: ConfigGroupConfig<CommandV2> = data;

          if (commandsConfig?.items) {
            commandsConfig.items = this.getCommandV2Items(commandsConfig.items);
          }

          return commandsConfig;
        }

        return null;
      }),
    );
  }

  public updateCommandsV2(projectId: string, defId: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.robotDefUpdateCommandsV2(projectId, defId, model).pipe(
      map((res: any) => {
        return res.data as any;
      }),
    );
  }

  public getCommandsForRobotV2(projectId: string, callsign: string): Observable<ConfigGroupConfig<CommandV2>> {
    return this.rocosClientService.rocosClient.robot.getCommandsV2(projectId, callsign).pipe(
      map((res: any) => {
        const data = res.data as any;
        if (data) {
          const commandsConfig: ConfigGroupConfig<CommandV2> = data;

          if (commandsConfig?.items) {
            commandsConfig.items = this.getCommandV2Items(commandsConfig.items);
          }

          return commandsConfig;
        }

        return null;
      }),
    );
  }

  public updateCommandsForRobotV2(projectId: string, callsign: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.updateCommandsV2(projectId, callsign, model).pipe(
      map((res: any) => {
        return res.data as any;
      }),
    );
  }

  // ----------------------
  // Storage Streams
  public updateStorageStreams(projectId: string, defId: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.robotDefsUpdateStorageStreams(projectId, defId, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  // ----------------------
  // Control Actions
  public getAvailableControlActions(projectId: string, defId: string): Observable<GamepadNewControlAction[]> {
    return this.rocosClientService.rocosClient.robot.robotDefGetCommandActions(projectId, defId).pipe(
      map((res) => {
        const data = res.data as any;
        return this.getControlActionsFromResponse(data);
      }),
    );
  }

  // ----------------------
  // Command V2 Types

  public getAvailableCommandV2Types(): Observable<IAvailableCommandV2TypesCollection> {
    return of(AvailableCommandV2Types);
  }

  // ----------------------
  // Buttons
  public getButtons(projectId: string, defId: string): Observable<ConfigGroupConfig<GamepadUxComponent>> {
    return forkJoin([
      this.rocosClientService.rocosClient.robot.robotDefGetButtons(projectId, defId),
      this.projectPermissionService.checkPermission(projectId, EXPERIMENTAL_FEATURE.ButtonRunJs),
      // ButtonAndGetMissionEnabled is based 2 feature flags
      this.projectPermissionService.checkPermission(projectId, [
        EXPERIMENTAL_FEATURE.ButtonRunJs,
        EXPERIMENTAL_FEATURE.OperationMissionsPanel,
      ]),
    ]).pipe(
      map(([res, isButtonRunJsEnabled, isButtonAndGetMissionEnabled]) => {
        const data = res.data as any;
        if (data) {
          const buttonsConfig: ConfigGroupConfig<GamepadUxComponent> = data;

          if (buttonsConfig?.items) {
            buttonsConfig.items = this.getButtonItems(
              buttonsConfig.items,
              isButtonRunJsEnabled,
              isButtonAndGetMissionEnabled,
            );
          }

          return buttonsConfig;
        }

        return null;
      }),
    );
  }

  public updateButtons(projectId: string, defId: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.robotDefUpdateButtons(projectId, defId, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public getButtonsForRobot(projectId: string, callsign: string): Observable<ConfigGroupConfig<GamepadUxComponent>> {
    return forkJoin([
      this.rocosClientService.rocosClient.robot.getButtons(projectId, callsign),
      this.projectPermissionService.checkPermission(projectId, EXPERIMENTAL_FEATURE.ButtonRunJs),
      // ButtonAndGetMissionEnabled is based 2 feature flags
      this.projectPermissionService.checkPermission(projectId, [
        EXPERIMENTAL_FEATURE.ButtonRunJs,
        EXPERIMENTAL_FEATURE.OperationMissionsPanel,
      ]),
    ]).pipe(
      map(([res, isButtonRunJsEnabled, isButtonAndGetMissionEnabled]) => {
        const data = res.data as any;
        if (data) {
          const buttonsConfig: ConfigGroupConfig<GamepadUxComponent> = data;

          if (buttonsConfig?.items) {
            buttonsConfig.items = this.getButtonItems(
              buttonsConfig.items,
              isButtonRunJsEnabled,
              isButtonAndGetMissionEnabled,
            );
          }

          return buttonsConfig;
        }

        return null;
      }),
    );
  }

  public updateButtonsForRobot(projectId: string, callsign: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.updateButtons(projectId, callsign, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  // ----------------------
  // Triggers

  public getTriggers(projectId: string, defId: string): Observable<ConfigGroupConfig<any>> {
    return this.rocosClientService.rocosClient.robot.robotDefGetTriggers(projectId, defId).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public updateTriggers(projectId: string, defId: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.robotDefUpdateTriggers(projectId, defId, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public getTriggersForRobot(projectId: string, callsign: string): Observable<any> {
    return this.rocosClientService.rocosClient.robot.getTriggers(projectId, callsign).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public updateTriggersForRobot(projectId: string, callsign: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.updateTriggers(projectId, callsign, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  // ----------------------
  // Settings

  public getSettings(projectId: string, defId: string): Observable<ConfigGroupConfig<any>> {
    return this.rocosClientService.rocosClient.robot.robotDefGetSettings(projectId, defId).pipe(
      map((res) => {
        const data = res.data as any;

        if (data) {
          const settingsConfig: ConfigGroupConfig<RobotSetting> = data;

          if (settingsConfig?.items) {
            settingsConfig.items = this.getSettingItems(settingsConfig.items);
          }

          return settingsConfig;
        }

        return data;
      }),
    );
  }

  public updateSettings(projectId: string, defId: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.robotDefUpdateSettings(projectId, defId, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public getSettingsForRobot(projectId: string, callsign: string): Observable<any> {
    return this.rocosClientService.rocosClient.robot.getSettings(projectId, callsign).pipe(
      map((res) => {
        const data = res.data as any;

        if (data) {
          const settingsConfig: ConfigGroupConfig<RobotSetting> = data;

          if (settingsConfig?.items) {
            settingsConfig.items = this.getSettingItems(settingsConfig.items);
          }

          return settingsConfig;
        }

        return data;
      }),
    );
  }

  public updateSettingsForRobot(projectId: string, callsign: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.updateSettings(projectId, callsign, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  // ----------------------
  // Agent Settings
  public getAgentSettings(projectId: string, defId: string): Observable<ConfigGroupConfig<any>> {
    return this.rocosClientService.rocosClient.robot.robotDefGetAgentSettings(projectId, defId).pipe(
      map((res) => {
        const data = res.data as any;

        if (data) {
          const settingsConfig: ConfigGroupConfig<RobotSetting> = data;

          if (settingsConfig?.items) {
            settingsConfig.items = this.getSettingItems(settingsConfig.items);
          }

          return settingsConfig;
        }

        return data;
      }),
    );
  }

  public updateAgentSettings(projectId: string, defId: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.robotDefUpdateAgentSettings(projectId, defId, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public getAgentSettingsForRobot(projectId: string, callsign: string): Observable<any> {
    return this.rocosClientService.rocosClient.robot.getAgentSettings(projectId, callsign).pipe(
      map((res) => {
        const data = res.data as any;

        if (data) {
          const settingsConfig: ConfigGroupConfig<RobotSetting> = data;

          if (settingsConfig?.items) {
            settingsConfig.items = this.getSettingItems(settingsConfig.items);
          }

          return settingsConfig;
        }

        return data;
      }),
    );
  }

  public getComponentSettingsForRobot(
    projectId: string,
    callsign: string,
    component: string,
  ): Observable<{ id: string; enabled: boolean; setting?: unknown } | undefined> {
    return this.getAgentSettingsForRobot(projectId, callsign).pipe(
      map((res) => {
        if (!res?.items?.length) return undefined;

        for (const el of res.items) {
          const setting = el.value?.settings?.find((s) => s.id === component);
          if (setting) return setting;
        }

        return undefined;
      }),
    );
  }

  public updateAgentSettingsForRobot(projectId: string, callsign: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.updateAgentSettings(projectId, callsign, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  // --------------
  // Settings + Agent Settings
  public getAllSettings(projectId: string, defId: string): Observable<ConfigGroupConfig<any>> {
    return forkJoin([this.getSettings(projectId, defId), this.getAgentSettings(projectId, defId)]).pipe(
      map(([commonSettings, agentSettings]) => {
        const settingsConfig = new ConfigGroupConfig<RobotSetting>();
        settingsConfig.items = [];

        if (commonSettings?.items) {
          settingsConfig.items.push(...commonSettings.items);
        }
        if (agentSettings?.items) {
          settingsConfig.items.push(...agentSettings.items);
        }

        return settingsConfig;
      }),
    );
  }

  // --------------
  // Gamepads
  public getGamepads(projectId: string, defId: string): Observable<ConfigGroupConfig<GamepadConfig>> {
    return this.rocosClientService.rocosClient.robot.robotDefGetGamepads(projectId, defId).pipe(
      map((res) => {
        const data = res.data as any;
        if (data) {
          const gamepadConfigs: ConfigGroupConfig<GamepadConfig> = data;

          if (gamepadConfigs?.items) {
            gamepadConfigs.items = this.getGamepadConfigItems(gamepadConfigs.items);
          }

          return gamepadConfigs;
        }

        return null;
      }),
    );
  }

  public updateGamepads(projectId: string, defId: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.robotDefUpdateGamepads(projectId, defId, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public getGamepadsForRobot(projectId: string, callsign: string): Observable<ConfigGroupConfig<GamepadConfig>> {
    return this.rocosClientService.rocosClient.robot.getGamepads(projectId, callsign).pipe(
      map((res) => {
        const data = res.data as any;
        if (data) {
          const gamepadConfigs: ConfigGroupConfig<GamepadConfig> = data;

          if (gamepadConfigs?.items) {
            gamepadConfigs.items = this.getGamepadConfigItems(gamepadConfigs.items);
          }

          return gamepadConfigs;
        }

        return null;
      }),
    );
  }

  public updateGamepadsForRobot(projectId: string, callsign: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.updateGamepads(projectId, callsign, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public getWidgets(items: any[]) {
    const newItems: ConfigGroupItem<Widget>[] = [];
    const newBaseGridSize = {
      width: defaultGridsterOptions.fixedColWidth,
      height: defaultGridsterOptions.fixedRowHeight,
      margin: defaultGridsterOptions.margin,
    };

    items.forEach((item) => {
      let widget: Widget;

      // adapt grid size
      // If baseGridSize not found, we assume the previous default size
      if (!item.value.baseGridSize) {
        item.value.baseGridSize = {
          width: 156,
          height: 196,
          margin: 16,
        };
      }

      if (item.value.baseGridSize && this.isDifferentBaseGridSize(item.value.baseGridSize, newBaseGridSize)) {
        const ocw = item.value.baseGridSize.width;
        const orh = item.value.baseGridSize.height;
        const om = item.value.baseGridSize.margin || 0;

        // new grid width
        const ncw = newBaseGridSize.width;
        // new grid height
        const nrh = newBaseGridSize.height;

        // Calculate new position x, y for the widget
        const oldX = item.value.x;
        const oldXInPixel = oldX * ocw + oldX * om;
        // We need an addtional x margin since we use math.ceil to convert old x to new x
        const addtionalXMargin = oldX > 7 ? 2 : oldX > 3 ? 1 : 0;
        const newX = Math.ceil(oldXInPixel / ncw) + addtionalXMargin;

        const oldY = item.value.y;
        const oldYInPixel = oldY * orh + oldY * om;
        // We need an addtional y margin since we use math.ceil to convert old y to new y
        const addtionalY = oldY > 0 ? oldY - 1 : 0;
        const newY = Math.ceil(oldYInPixel / nrh) + addtionalY;

        item.value.x = newX;
        item.value.y = newY;

        // Calculate new col and row for the widget
        const oldCols = item.value.cols;
        const oldWidth = oldCols * ocw + (oldCols - 1) * om;
        const addtionalCol = oldCols > 3 ? 1 : 0;
        const newCol = Math.ceil(oldWidth / ncw) + addtionalCol;

        const oldRows = item.value.rows;
        const oldHeight = oldRows * orh + (oldRows - 1) * om;
        const addtionalRow = oldRows > 1 ? 1 : 0;
        const newRow = Math.ceil(oldHeight / nrh) + addtionalRow;

        item.value.cols = newCol;
        item.value.rows = newRow;

        // also need to update the min size
        if (item.value.minItemRows) {
          item.value.minItemRows = Math.ceil((item.value.minItemRows * item.value.rows) / oldRows);
        }
        if (item.value.minItemCols) {
          item.value.minItemCols = Math.ceil((item.value.minItemCols * item.value.cols) / oldCols);
        }

        // update baseGridSize to latest
        item.value.baseGridSize = newBaseGridSize;
      }

      if (item.value) {
        switch (item.value.widgetType) {
          case 'metric':
            widget = WidgetMetric.fromModel(item.value);
            break;
          case 'donut-gauge':
            widget = WidgetDonutGauge.fromModel(item.value);
            break;
          case 'line-graph':
          case 'line-graph-v2':
            widget = WidgetLineGraph.fromModel(item.value);
            break;
          case 'audio':
            widget = WidgetAudio.fromModel(item.value);
            break;
          case 'gamepad':
            widget = WidgetGamepad.fromModel(item.value);
            break;
          case 'button-group':
            widget = WidgetButtonGroup.fromModel(item.value);
            break;
          case 'tableV2':
            widget = WidgetTableV2.fromModel(item.value);
            break;
          case 'table':
            widget = WidgetTable.fromModel(item.value);
            break;
          case 'video':
            widget = WidgetVideo.fromModel(item.value);
            break;
          case 'html':
            widget = WidgetHTML.fromModel(item.value);
            break;
          case 'image':
            widget = WidgetImage.fromModel(item.value);
            break;
          case 'ops':
            widget = WidgetOps.fromModel(item.value);
            break;
          case 'custom':
            widget = WidgetCustom.fromModel(item.value);
            break;
          case 'web-component':
            widget = WidgetWebComponent.fromModel(item.value);
            break;
        }
      }

      if (widget) {
        item.value = widget;

        newItems.push(item);
      }
    });

    return newItems;
  }

  public getControlActionsFromResponse(data: any): GamepadNewControlAction[] {
    const actions: GamepadNewControlAction[] = [];

    if (!data?.items || data.items.length === 0) {
      // Use sample data.
      data = controlActionsSampleData;
    }

    if (data?.items && data.items.length > 0) {
      const items: any[] = data.items;

      // Find target value
      const actionItems = items
        .filter((item) => {
          return item?.value && item.value.id === 'command-actions';
        })
        .map((item) => {
          return item.value.actions;
        });

      if (actionItems) {
        actionItems.forEach((item) => {
          actions.push(
            ...item.map((action) => {
              return GamepadNewControlAction.fromModel(action);
            }),
          );
        });
      }
    }

    return actions;
  }

  public updateChangeStatus(items: any[]) {
    this.changeStreamStatus.emit(items);
  }

  private isDifferentBaseGridSize(oldGrid, newGrid) {
    return oldGrid.width !== newGrid.width || oldGrid.height !== newGrid.height || oldGrid.margin !== newGrid.margin;
  }

  private getGamepadConfigItems(items: any[]): ConfigGroupItem<GamepadConfig>[] {
    return items.map((item) => {
      if (item.value) {
        item.value = GamepadConfig.fromJSON(item.value);
      }
      return item;
    }) as ConfigGroupItem<GamepadConfig>[];
  }

  private getControlItems(items: any[]) {
    const controls: ConfigGroupItem<GamepadControl>[] = items.map((item) => {
      if (item.value) {
        item.value = GamepadControl.fromModel(item.value);
      }

      return item;
    });

    return controls;
  }

  private getCommandV2Items(items: any[]) {
    const commands: ConfigGroupItem<CommandV2>[] = items
      .map((item) => {
        if (item.value) {
          const command = CommandV2.fromModel(item.value);

          if (command) {
            item.value = command;
            return item;
          }
        }
      })
      .filter(Boolean);

    return commands;
  }

  private getButtonItems(items: any[], isButtonRunJsEnabled: boolean, isButtonAndGetMissionEnabled: boolean) {
    const buttonItems: ConfigGroupItem<GamepadUxComponent>[] = items.map((item) => {
      if (!item.value) {
        return item;
      }

      const button = GamepadUxComponent.fromModel(item.value);
      item.value = button;

      // Return item directly if the user is admin
      if (this.userService.isAdmin() && !this.devService.isHideAdminOnlyContent) {
        return item;
      }

      // If isButtonAndGetMissionEnabled is not enabled,
      // we want to remove the buttonAndGetMission button from the result
      if (button.controlType === 'buttonAndGetMission' && !isButtonAndGetMissionEnabled) {
        return undefined;
      }

      // If isButtonRunJsEnabled is not enabled, we want to remove the run-js button from the result
      if (button.commandVersion === 'run-js' && !isButtonRunJsEnabled) {
        return undefined;
      }

      return item;
    });

    return buttonItems.filter(Boolean);
  }

  private getSettingItems(items: any[]): ConfigGroupItem<RobotSetting>[] {
    items = items.map((item) => {
      return ConfigGroupItem.fromModel(item);
    });

    return items.map((item) => {
      if (item.value) {
        item.value = RobotSetting.fromModel(item.value);
      }
      return item;
    }) as ConfigGroupItem<RobotSetting>[];
  }

  // public robotDefsRemove(
  //   projectId: string,
  //   defIds: string[],
  // ): Observable<any> {d
  //   return this.httpRequest.delete(
  //     config.endPoints.robot.delete(projectId),
  //     defIds,
  //   );
  // }

  // public robotDefsGetOne(
  //   projectId: string,
  //   defId: string,
  // ): Observable<any> {
  //   return this.httpRequest.get(
  //     config.endPoints.robotDefs.getOne(projectId, defId),
  //   );
  // }

  // public robotDefsUpdateOne(
  //   projectId: string,
  //   defId: string,
  //   model: any,
  // ): Observable<any> {
  //   return this.httpRequest.put(
  //     config.endPoints.robotDefs.updateOne(projectId, defId),
  //     model,
  //   );
  // }

  // public robotDefsForRobot(
  //   projectId: string,
  //   callsign: string,
  // ): Observable<any> {
  //   return this.httpRequest.get(
  //     config.endPoints.robotDefs.forRobot(projectId, callsign),
  //   );
  // }
}
