import { inject } from '@angular/core';
import type { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import type { FeatureFlags } from '@shared/services/feature-flag/feature-flags';
import { firstValueFrom } from 'rxjs';

export const FeatureFlagGuard: CanActivateFn = async (route: ActivatedRouteSnapshot) => {
  const featureName: FeatureFlags | FeatureFlags[] = route.data['featureFlag'];

  if (!featureName || (typeof featureName !== 'string' && !Array.isArray(featureName))) {
    return false;
  }

  const featureService = inject(FeatureFlagService);

  // avoid getting the default false
  await featureService.waitUntilReady();

  return firstValueFrom(featureService.getPermission(featureName));
};
