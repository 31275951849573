import { Inject, Injectable, InjectionToken } from '@angular/core';
import { environment } from '@env/environment';
import { AuthService } from '@shared/services';
import type { LDClient, LDContext, LDFlagChangeset, LDFlagSet, LDOptions } from 'launchdarkly-js-client-sdk';
import { initialize } from 'launchdarkly-js-client-sdk';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { defaultFeatureFlags, FeatureFlags } from './feature-flags';

type FlagInitializer = (envKey: string, context: LDContext, options?: LDOptions) => LDClient;

// This token is used to provide the function that initialises the LaunchDarkly client.
// This is useful for testing purposes as it allows the client to be mocked without complicated module mocking.
export const LD_INITIALISE_TOKEN = new InjectionToken<FlagInitializer>('LD_INITIALISE_TOKEN', {
  factory: () => initialize,
});

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  public featureFlags$: Observable<Record<FeatureFlags, boolean>>;
  private _featureFlags$ = new BehaviorSubject<Record<FeatureFlags, boolean>>(defaultFeatureFlags);

  private client: LDClient;

  public constructor(
    private authService: AuthService,
    @Inject(LD_INITIALISE_TOKEN) ldClientInitialize: FlagInitializer,
  ) {
    const userId = this.authService.userInfo.id.split('|').at(-1);

    this.client = ldClientInitialize(environment.launchDarkly.clientId, {
      kind: 'user',
      key: userId,
      email: this.authService.userInfo.email,
      firstName: this.authService.userInfo.firstName,
      lastName: this.authService.userInfo.lastName,
    });

    this.client.on('change', (flags: LDFlagChangeset) => {
      this.updateFlags(flags);
    });

    this.client.on('ready', () => {
      this.updateFlags(this.client.allFlags());
    });

    this.featureFlags$ = this._featureFlags$.asObservable();
  }

  public waitUntilReady(): Promise<void> {
    return this.client.waitUntilReady();
  }

  /** Check if the user has permission to access a feature
   *
   * @param featureFlag The feature to check.
   *                    If an array is passed, all features must be enabled to return true.
   *
   * @returns Observable<boolean> A boolean observable that emits true if the user has permission to access the feature.
   *                              Otherwise, it emits false.
   *
   *                              If the feature is not found, an error is thrown.
   **/
  public getPermission(featureFlag: FeatureFlags | FeatureFlags[]): Observable<boolean> {
    const features = Array.isArray(featureFlag) ? featureFlag : [featureFlag];
    return this._featureFlags$.pipe(
      map((flags) => {
        for (const feature of features) {
          if (!(feature in flags)) {
            throw new Error(`feature flag '${feature}' not found.`);
          }

          if (!flags[feature]) {
            return false;
          }
        }

        return true;
      }),
    );
  }

  private updateFlags = (flags: LDFlagSet | LDFlagChangeset) => {
    const newFlags: Partial<Record<FeatureFlags, boolean>> = {};
    for (const feature of Object.values(FeatureFlags)) {
      newFlags[feature] =
        flags[feature] && isLDFlagChangeset(flags[feature])
          ? flags[feature].current
          : flags[feature] ?? defaultFeatureFlags[feature];
    }

    this._featureFlags$.next({ ...this._featureFlags$.getValue(), ...newFlags });
  };
}

const isLDFlagChangeset = (flags: LDFlagSet | LDFlagChangeset): flags is LDFlagChangeset => {
  return Object.prototype.hasOwnProperty.call(flags, 'current');
};
