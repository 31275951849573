import type { AfterViewInit, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MatLegacyTable as MatTable,
  MatLegacyTableDataSource as MatTableDataSource,
} from '@angular/material/legacy-table';
import { first } from 'rxjs/operators';
import { DialogService } from '../../dialogs';
import type { CommandV2, GamepadControl, GamepadUxComponent } from '../../models';
import { ConfigGroupItem } from '../../models';
import { ToastService } from '../../services';
import { ScreenComponentEditDialogComponent } from '../screen-component-edit-dialog';
import { CommandActionPipe } from '@shared/gamepad/command-version.pipe';
import type { MatSortable } from '@angular/material/sort';
import { MatSort } from '@angular/material/sort';

type GamepadConfig = ConfigGroupItem<GamepadUxComponent>;

@Component({
  selector: 'app-screen-components',
  templateUrl: './screen-components.component.html',
  styleUrls: ['./screen-components.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CommandActionPipe],
})
export class ScreenComponentsComponent implements OnInit, AfterViewInit {
  @Input() isLoading: boolean = false;
  @Input() currentModule: 'robot' | 'robot-definition' = 'robot';
  @Input() buttonStatus: any;
  @Input() projectId: string;
  @Input() callsign: string;

  @Input() set items(value: GamepadConfig[]) {
    this._items = value;
    this.dataSource.data = value;
    if (this.dataSource.sort) this.dataSource.sortData(this.dataSource.data, this.dataSource.sort);
    this.componentsTable?.renderRows();
  }
  get items(): GamepadConfig[] {
    return this._items;
  }

  @Input() controlItems: ConfigGroupItem<GamepadControl>[] = [];
  @Input() commandV2Items: ConfigGroupItem<CommandV2>[] = [];
  @Output() itemsChange: EventEmitter<ConfigGroupItem<GamepadUxComponent>[]> = new EventEmitter<GamepadConfig[]>();
  @Output() itemClick: EventEmitter<GamepadConfig> = new EventEmitter<GamepadConfig>();
  @Output() removeItemChange: EventEmitter<GamepadConfig> = new EventEmitter<GamepadConfig>();

  @ViewChild('componentsTable') componentsTable: MatTable<GamepadConfig>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dataSource = new MatTableDataSource<GamepadConfig>();
  displayedColumns: string[] = ['label', 'description', 'command', 'editControls'];

  isDirectlyExecuteCommands: boolean = false;

  private _items: GamepadConfig[] = [];

  get shouldEnableActivateToggle(): boolean {
    return !(this.currentModule === 'robot-definition' && !this.callsign);
  }

  constructor(
    private dialog: MatDialog,
    private toast: ToastService,
    private dialogService: DialogService,
    private commandVersionPipe: CommandActionPipe,
  ) {}

  public ngOnInit(): void {
    // Sort by label by default
    const defaultSort: MatSortable = { id: 'label', start: 'asc', disableClear: false };
    this.sort?.sort(defaultSort);

    this.dataSource.sortingDataAccessor = (item, property) => {
      if (!item.value) return null;

      switch (property) {
        case 'label':
          return item.value.label.toLowerCase();
        case 'description':
          return item.value?.description?.toLowerCase();
        case 'command':
          return this.commandVersionPipe.transform(item.value);
        default:
          return item.value[property];
      }
    };
    this.dataSource.sort = this.sort;
  }

  public ngAfterViewInit(): void {
    this.componentsTable.trackBy = (_, item) => {
      return item.value.id;
    };
  }

  onClick(item: GamepadConfig) {
    if (this.isDirectlyExecuteCommands) {
      this.itemClick.next(item);
    } else {
      this.toast.short(
        `Select 'Activate buttons' to enable the test buttons to initiate the configured command`,
        null,
        'info',
      );
    }
  }

  onAddNew() {
    this.openEditDialog();
  }

  onEdit(item: GamepadConfig) {
    this.openEditDialog(item);
  }

  onDelete(item: GamepadConfig) {
    const title = 'Delete Component?';
    const message = '<p>Are you sure you want to delete this component?</p>';

    this.dialogService
      .deleteConfirm(title, message)
      .pipe(first())
      .subscribe((confirmed) => {
        if (confirmed) {
          this.componentsTableChanged(this.items.filter((i) => i.value.id !== item.value.id));

          this.removeItemChange.next(item);
        }
      });
  }

  private componentsTableChanged(items: GamepadConfig[]) {
    this.itemsChange.next(items);
  }

  private openEditDialog(item?: GamepadConfig) {
    const dialogRef = this.dialog.open(ScreenComponentEditDialogComponent, {
      data: {
        projectId: this.projectId,
        component: item?.value,
        controls: ConfigGroupItem.getValues<GamepadControl>(this.controlItems),
        commandsV2: ConfigGroupItem.getValues<CommandV2>(this.commandV2Items),
      },
    });

    const isAddingNewOne = !item;

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.id) {
        // Got component
        if (isAddingNewOne) {
          const newItem = new ConfigGroupItem<GamepadUxComponent>(result);

          if (this.currentModule === 'robot') {
            newItem._isOverridden = true;
          }

          this.items.push(newItem);
        } else {
          item.value = result;
        }

        this.componentsTableChanged([...this.items]);
      }
    });
  }
}
