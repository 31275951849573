<div class="rs-dialog data-explorer-ui">
  <h2 mat-dialog-title class="title">
    <span>Data Picker</span>
    <span class="right-buttons">
      <button type="button" mat-icon-button (click)="onCancel()" matTooltip="Cancel" tabindex="-1">
        <span class="ri-cancel icon-button grey-icon"></span>
      </button>
    </span>
  </h2>

  <app-data-explorer-wrapper
    [callsign]="callsign"
    [projectId]="projectId"
    [enableRobotPicker]="!callsign"
    [insertDataURIMode]="!hidePicker"
    [showServiceMode]="showServices"
    (dataChange)="onDataExplorerChange($event)"
  >
  </app-data-explorer-wrapper>
</div>
