import { Component, Inject } from '@angular/core';
import type { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import type { ConfirmActionType } from '../confirm-action-type';
import { TranslocoService } from '@ngneat/transloco';
import { StringUtils } from '../../utils';

export interface ConfirmDialogData {
  title: string;
  message: string;
  actionButtonText?: string;
  cancelButtonText?: string;
  actionType: ConfirmActionType;
  confirmText?: string;
  confirmWarningMessage?: string;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements ConfirmDialogData {
  title: string = 'Confirm';
  message: string = this.translocoService.translate('core.comfirmDialog.doYouWantToContinue');
  actionButtonText: string = this.translocoService.translate('common.confirm');
  cancelButtonText: string = this.translocoService.translate('common.cancel');
  actionType: ConfirmActionType = 'confirm';
  confirmText: string;
  confirmedText: string;
  confirmWarningMessage: string;

  public get actionButtonColor(): ThemePalette {
    let color: ThemePalette = 'primary';
    if (this.actionType === 'delete') color = 'warn';
    return color;
  }

  stringUtils = StringUtils;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) private data: ConfirmDialogData,
    private translocoService: TranslocoService,
  ) {
    if (!data) return;

    if (data.title) this.title = data.title;
    if (data.message) this.message = data.message;
    if (data.actionButtonText) this.actionButtonText = data.actionButtonText;
    if (data.cancelButtonText) this.cancelButtonText = data.cancelButtonText;
    if (data.actionType) this.actionType = data.actionType;
    if (data.confirmText) this.confirmText = StringUtils.trim(data.confirmText);
    if (data.confirmWarningMessage) this.confirmWarningMessage = data.confirmWarningMessage;
  }
}
