import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum UnpublishedChangesResult {
  cancel = 'cancel',
  discard = 'discard',
  publish = 'publish',
}

export interface UnpublishedChangesDialogData {
  title?: string;
  message?: string;
  canPublish?: boolean;
  buttons?: {
    publish?: string;
    cancel?: string;
    discard?: string;
  };
}

@Component({
  selector: 'app-unpublished-changes-dialog',
  templateUrl: './unpublished-changes-dialog.component.html',
  styleUrls: ['./unpublished-changes-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnpublishedChangesDialogComponent implements OnInit {
  protected title: string = 'Unpublished Changes';

  constructor(
    private dialogRef: MatDialogRef<UnpublishedChangesDialogComponent, UnpublishedChangesResult>,
    @Inject(MAT_DIALOG_DATA) protected readonly data: UnpublishedChangesDialogData = {},
  ) {}

  public ngOnInit(): void {
    if (this.data.title) this.title = this.data.title;
  }

  public onPublishButtonClick(): void {
    this.dialogRef.close(UnpublishedChangesResult.publish);
  }

  public onCancelButtonClick(): void {
    this.dialogRef.close(UnpublishedChangesResult.cancel);
  }

  public onDiscardButtonClick(): void {
    this.dialogRef.close(UnpublishedChangesResult.discard);
  }

  protected getMessage(): string {
    if (this.data.message) return this.data.message;

    if (this.data.canPublish) {
      return 'You have unpublished changes. Would you like to save or discard them?';
    }

    return 'Are you sure you want to leave without publishing? Any unpublished changes will be lost.';
  }
}
