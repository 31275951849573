import type { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import type { AbstractControl } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import type { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { FormUtils } from '@shared/utils/form-utils';
import { EXPERIMENTAL_FEATURE } from '../../enums';
import type {
  ButtonDef,
  CommandV2,
  GamepadButtonAction,
  GamepadControl,
  GamepadUxComponent,
  GamepadUxComponentCommandVersion,
} from '../../models';
import { GamepadButtonTriggerType } from '../../models';
import { DevelopmentService, ProjectPermissionService, UserService } from '../../services';

export class GamepadButtonActionDialogData {
  projectId: string;
  component: GamepadUxComponent;
  model: any;
  controls: GamepadControl[];
  commandsV2: CommandV2[];
  sources: ButtonDef[];
}

@Component({
  selector: 'app-gamepad-button-action-dialog',
  templateUrl: './gamepad-button-action-dialog.component.html',
  styleUrls: ['./gamepad-button-action-dialog.component.scss'],
})
export class GamepadButtonActionDialogComponent implements OnInit {
  form: UntypedFormGroup = new UntypedFormGroup({});
  model: GamepadButtonAction;
  trigerTypeEnum = GamepadButtonTriggerType;
  controls: GamepadControl[];
  sources: ButtonDef[];
  commandsV2: CommandV2[];

  public get commandId() {
    return this.form?.get('commandId');
  }
  public get commandVersion(): AbstractControl {
    return FormUtils.getControl(this.form, 'commandVersion');
  }

  controlsTabEnabled: boolean = false;
  controlAlreadyUsedByThisAction: boolean;
  isAdmin: boolean;
  projectId: string;

  constructor(
    public dialogRef: MatDialogRef<GamepadButtonActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: GamepadButtonActionDialogData,
    private projectPermissionService: ProjectPermissionService,
    private userService: UserService,
    private devService: DevelopmentService,
  ) {
    this.isAdmin = this.userService.isAdmin();

    if (data) {
      if (data.model) {
        this.model = data.model;
      }
      if (data.controls) {
        this.controls = data.controls;
      }

      if (data.projectId) {
        this.projectId = data.projectId;
      }

      if (data.commandsV2) {
        this.commandsV2 = data.commandsV2;
      }

      if (data.sources) {
        this.sources = data.sources;
      }
    }

    if (this.model?.commandVersion !== 'commands-v2') {
      this.controlAlreadyUsedByThisAction = true;
    }

    if (this.projectId) {
      // Get ExperimentalFeature.ControlsTab flag status
      this.projectPermissionService.checkPermission(this.projectId, EXPERIMENTAL_FEATURE.ControlsTab).subscribe((x) => {
        this.controlsTabEnabled = x;
      });
    }
  }

  ngOnInit() {
    this.createForm(this.model);
  }

  // Identify if the control option is available
  get isControlsVisible() {
    return (
      this.controlsTabEnabled ||
      this.controlAlreadyUsedByThisAction ||
      (this.isAdmin && !this.devService.isHideAdminOnlyContent)
    );
  }

  onSubmit() {
    if (!this.form.valid) {
      this.form.markAsDirty();
      return;
    }

    const data = this.form.value;
    data.source = {
      type: data.source,
    };

    this.dialogRef.close(data);
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSwitchcommandVersion(change: MatRadioChange) {
    // Reset command name as command version changed.
    this.commandId.setValue(null);

    const value = change.value;

    this.switchcommandVersion(value);
  }

  setControls(controls: GamepadControl[]) {
    this.controls = controls;
  }

  setSources(sources: ButtonDef[]) {
    this.sources = sources;
  }

  createForm(model?: GamepadButtonAction) {
    // backward compatible, set the defalut command version to controls
    // this default value will be used if commandVersion flag is missing in the gamepad action
    let defaultCommandVersion = 'controls';

    // For new action, set the default to commands-v2
    if (!this.model) {
      defaultCommandVersion = 'commands-v2';
    }

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(model?.name ? model.name : '', [Validators.required]),
      source: new UntypedFormControl(model?.source ? model.source.type : null, [Validators.required]),
      frequency: new UntypedFormControl(model?.frequency ? model.frequency : 1000, [Validators.required]),
      triggerType: new UntypedFormControl(model?.triggerType ? model.triggerType : GamepadButtonTriggerType.ONCE, [
        Validators.required,
      ]),
      commandId: new UntypedFormControl(model?.commandId ? model.commandId : null, [Validators.required]),
      commandVersion: new UntypedFormControl(model?.commandVersion ? model.commandVersion : defaultCommandVersion, [
        Validators.required,
      ]),
    });
  }

  private switchcommandVersion(commandVersion: GamepadUxComponentCommandVersion) {
    switch (commandVersion) {
      case 'controls':
      case 'commands-v2':
      case 'commands': // this case is for backward compability for old version of command
        this.commandId.setValidators([Validators.required]);
        break;
      default:
        break;
    }
  }
}
