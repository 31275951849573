import { Injectable } from '@angular/core';
import { TelemetrySinkService } from '@shared/services/telemetry-sink/telemetry-sink.service';
import type { Subscription } from 'rxjs';
import { BehaviorSubject, from, merge, Observable } from 'rxjs';
import type {
  ControlAckMessage,
  IRocosTelemetryMessage,
  OpResultMessage,
  RocosResponse,
  SlowLaneDataRowMessage,
  StreamQuery,
  SubscriberStatus,
} from '@team-rocos/rocos-js';
import {
  FastLaneManager,
  FileAccessor,
  FileResourceType,
  GrpcClient,
  PigeonOperatorService,
  RamboService,
  RobotController,
  RocosClient,
  Serviette,
} from '@team-rocos/rocos-js';
import { environment } from '@env/environment';
import { StateService } from '../state';
import { map, mergeMap } from 'rxjs/operators';
import { generateOutputForJSONSchema } from '../../utils';
import { RocosSdkClientService } from '@shared/services';

export class SubscribeResult {
  public observable: Observable<IRocosTelemetryMessage>;
  public subStatusObservable?: Observable<SubscriberStatus>;
  public subscription?: Subscription;
  public subStatusSubscription?: Subscription;
  public uniqueId?: string;
  public instanceId?: string;
  public fastLaneManager?: FastLaneManager;
}

export const rocosJsVariableName = 'clientService';

/**
 * @deprecated Use RocosSdkClientService instead
 */
@Injectable({
  providedIn: 'root',
})
export class RocosClientService {
  public rocosClient: RocosClient;
  public fastLaneManager: FastLaneManager;
  public robotController: RobotController;
  public fileAccessor: FileAccessor;
  public pigeonOperatorService: PigeonOperatorService;
  public ramboService: RamboService;
  public servietteService: Serviette.ServietteService;
  public token$ = new BehaviorSubject<string>(null);
  public serviettteServicesCache: BehaviorSubject<any>;
  public telemetrySourceCache: BehaviorSubject<any>;
  public uidCache: any = {};

  private cachedChunksDict: any = {};
  private grpcClient: GrpcClient;

  constructor(
    private stateService: StateService,
    public telemetrySink: TelemetrySinkService,
    private sdk: RocosSdkClientService,
  ) {
    this.rocosClient = new RocosClient({
      baseURL: environment.api.url,
      interceptor: this.interceptor.bind(this),
    });
    this.grpcClient = new GrpcClient(environment.api.grpcUrl);
    this.fastLaneManager = new FastLaneManager(environment.api.grpcUrl);
    this.robotController = new RobotController(environment.api.grpcUrl);
    this.fileAccessor = new FileAccessor(environment.api.grpcUrl);
    this.pigeonOperatorService = new PigeonOperatorService(environment.api.grpcUrl);
    this.ramboService = new RamboService(environment.api.grpcUrl);
    this.servietteService = new Serviette.ServietteService(environment.api.grpcUrl);

    this.serviettteServicesCache = new BehaviorSubject<any>(null);
    this.telemetrySourceCache = new BehaviorSubject<any>(null);

    this.publicSDKToWindow();
  }

  public get platformTime() {
    return this.rocosClient.platformTime;
  }

  cancelService(projectId: string, callsign: string, path: string, uid?: string) {
    if (!uid) {
      uid = this.getUidFromLocalCache(projectId, callsign, path);
    }

    if (!uid) {
      uid = this.getUid(path);
    }

    if (uid) {
      return this.servietteService.serviceCallerCancelRequest(projectId, callsign, '', uid).pipe(
        map((x) => {
          return this.getResultFromServiceCallerResponse(x);
        }),
      );
    }
    return undefined;
  }

  callService(
    projectId: string,
    callsign: string,
    path: string,
    payload: string,
    uid?: string,
    query?: Record<string, string[]>,
  ) {
    const pathArray = path.split('/');
    const component = pathArray[1];
    const topic = pathArray.slice(2).join('/');
    const requestPayload = btoa(payload);

    if (!uid) {
      uid = crypto.randomUUID();
    }

    this.cacheUid(projectId, callsign, path, uid);

    return this.servietteService
      .serviceCallerInvokeRequest(uid, projectId, callsign, '', component, topic, 0, requestPayload, query)
      .pipe(
        map((x) => {
          return this.getResultFromServiceCallerResponse(x);
        }),
      );
  }

  cacheUid(projectId: string, callsign: string, path: string, uid: string) {
    const uri = this.getUri(projectId, callsign, path);

    if (!this.uidCache) {
      this.uidCache = {};
    }
    this.uidCache[uri] = uid;
  }

  getUidFromLocalCache(projectId: string, callsign: string, path: string) {
    const key = this.getUri(projectId, callsign, path);
    if (this.uidCache?.[key]) {
      return this.uidCache[key];
    }

    return null;
  }

  getUri(projectId: string, callsign: string, path: string) {
    return `${callsign}.${projectId}${path}`;
  }

  getUid(url: string) {
    const cache = this.serviettteServicesCache.value;
    if (cache) {
      const sourcePayload = cache[url];
      if (sourcePayload?.uid) {
        return sourcePayload.uid;
      }
    }
    return null;
  }

  getContentMediaType(url: string) {
    let contentMediaType = '';
    if (this.telemetrySourceCache?.value) {
      const cache = this.telemetrySourceCache.value;
      const sourcePayload = cache[url];
      if (sourcePayload?.schema) {
        const schema = sourcePayload.schema;
        const schemaAsObject = JSON.parse(schema);
        if (schemaAsObject.contentMediaType) {
          contentMediaType = schemaAsObject.contentMediaType;
        }
      }
    }
    return contentMediaType;
  }

  getServiceRequestSchema(url: string) {
    const cache = this.serviettteServicesCache.value;
    const sourcePayload = cache[url];
    if (sourcePayload?.requestSchema) {
      const schema = sourcePayload.requestSchema;

      try {
        const schemaAsObject = JSON.parse(schema);
        const payload = this.getServicePayloadFromSchema(schemaAsObject);
        return JSON.stringify(payload, null, 2);
      } catch (e) {
        console.error('Fail to parse service payload schema', {
          url,
          err: e,
        });
      }
    }

    return '';
  }

  getServicePayloadFromSchema(schemaAsObject: any) {
    return generateOutputForJSONSchema(schemaAsObject);
  }

  getResultFromServiceCallerResponse(res) {
    if (res.serviceCallerResponse?.responsesList) {
      return this.getResultFromServiceCallerResponseInternal(res);
    }

    if (res.serviceCallerResponse?.chunksList) {
      return this.getResultFromServiceCallerChunks(res.serviceCallerResponse.chunksList);
    }

    return null;
  }

  getResultStatus(x) {
    const status = null;

    if (!x) {
      return status;
    }

    if (!x.serviceCallerResponse) {
      return status;
    }

    if (!x.serviceCallerResponse.responsesList) {
      return status;
    }

    if (!x.serviceCallerResponse.responsesList[0]) {
      return status;
    }

    if (!x.serviceCallerResponse.responsesList[0].result) {
      return status;
    }

    return x.serviceCallerResponse.responsesList[0].result.status;
  }

  async getServiceCallerObservable(
    projectId: string,
    callsign: string,
    isTelemetry: boolean,
    isAdmin: boolean,
  ): Promise<Observable<any>> {
    let topic = 'getServices';
    const cachedTelemetrySourcePayloads = [];
    const cachedSourcePayloads = [];

    if (isTelemetry) {
      topic = 'getTelemetry';
      this.telemetrySourceCache.next(cachedTelemetrySourcePayloads);
    } else {
      this.serviettteServicesCache.next(cachedSourcePayloads);
    }

    let payloadString = `{
        "query": {
          "type":0,
          "data": {
            "depth": 0,
            "path": "*",
            "showHidden": false
          }
        }
      }`;

    if (isAdmin) {
      payloadString = `{
        "query": {
          "type":0,
          "data": {
            "depth": 0,
            "path": "*",
            "showHidden": true
          }
        }
      }`;
    }

    const requestPayload = btoa(payloadString);
    const sources = {
      projectId,
      callsign,
      type: 'service',
    };

    if (isTelemetry) {
      sources.type = 'telemetry';
    }

    const observables = [];

    let components = [];
    let getCallables: Subscription;
    const getCallablesPromise = new Promise((resolve) => {
      const uid = crypto.randomUUID();
      const maxTimeout = 10000;
      // only wait for 10 seconds
      setTimeout(() => {
        resolve([]);
      }, maxTimeout);
      getCallables = this.servietteService
        .serviceCallerInvokeRequest(uid, projectId, callsign, '', 'rocos', 'getCallables', 0, requestPayload)
        .subscribe((x) => {
          const status = this.getResultStatus(x);

          if (status === 16) {
            resolve([]);
          }

          const results = this.getResultFromServiceCallerResponse(x);

          if (!results || results.length === 0) {
            return;
          }

          let callables = results[0].payload;

          if (callables?.length > 0) {
            callables = callables.filter((y) => {
              return !!y?.Instance;
            });

            components = callables.map((y) => {
              return y.Instance;
            });

            if (components?.length > 0) {
              resolve(components);
            }
          }
        });
    });

    // wait components to comeback
    await getCallablesPromise;

    if (getCallables) {
      // we have got what we want, unsubscribe
      getCallables.unsubscribe();
    }

    // components = [
    //   "ros",
    //   "video",
    //   "system",
    //   "tcp",
    //   "events",
    //   "rocos",
    //   "load-test"
    // ];

    components.forEach((c) => {
      const uid = crypto.randomUUID();
      const obs = this.servietteService
        .serviceCallerInvokeRequest(uid, projectId, callsign, '', c, topic, 0, requestPayload)
        .pipe(
          map((x) => {
            const res = x.serviceCallerResponse as any;
            if (res && (res.responsesList || res.chunksList)) {
              let sourcePayloads = [];
              const results = this.getResultFromServiceCallerResponse(x);
              if (results?.length > 0) {
                results.forEach((result) => {
                  const payload = result.payload;
                  if (payload) {
                    if (isTelemetry) {
                      if (Array.isArray(payload)) {
                        // payload.forEach(element => {
                        //   element.schema = element.schema.replace(/"title"\s*:\s*"([^"]+?)",/g, '"temptitle":"$1",');
                        // });
                        sourcePayloads = payload;
                      } else {
                        Object.keys(payload).forEach((y) => {
                          const sourcePayload = {
                            'source': '/' + c + '/' + y,
                            'schema': payload[y].schema,
                            'children': payload[y].children,
                            'hash': payload[y].hash,
                          };
                          sourcePayloads.push(sourcePayload);
                          cachedTelemetrySourcePayloads[sourcePayload.source] = sourcePayload;
                        });
                      }
                    } else {
                      Object.keys(payload).forEach((y) => {
                        const sourcePayload = {
                          'source': '/' + c + '/' + y,
                          'requestSchema': '',
                          'responseSchema': '',
                          'schema': '',
                          'uid': result.uid,
                        };

                        if (payload[y].schema) {
                          sourcePayload.requestSchema = payload[y].schema.request;
                          sourcePayload.responseSchema = payload[y].schema.response;
                          sourcePayload.schema = payload[y].schema.response;
                        }

                        sourcePayloads.push(sourcePayload);
                        cachedSourcePayloads[sourcePayload.source] = sourcePayload;
                      });
                    }
                  }
                });
              }

              const sourcesClone = JSON.parse(JSON.stringify(sources));
              sourcesClone.component = c;
              sourcesClone.source = '/' + c + '/' + topic;
              sourcesClone.payload = sourcePayloads;
              if (isTelemetry) {
                this.telemetrySourceCache.next(cachedTelemetrySourcePayloads);
              } else {
                this.serviettteServicesCache.next(cachedSourcePayloads);
              }
              return sourcesClone;
            }
            return null;
          }),
        );

      observables.push(obs);
    });

    if (observables?.length > 0) {
      return Promise.resolve(merge(...observables));
    }
    return Promise.resolve(null);
  }

  updateToken(token: string) {
    this.token$.next(token);
    this.rocosClient.updateToken(token);
    this.grpcClient.updateToken(token);
    this.fastLaneManager.updateToken(token);
    this.robotController.updateToken(token);
    this.fileAccessor.updateToken(token);
    this.pigeonOperatorService.updateToken(token);
    this.ramboService.updateToken(token);
    this.servietteService.updateToken(token);
  }

  public interceptor(res: any) {
    switch (res.statusCode) {
      case 401:
        this.stateService.gotUnauthorizedError(res);
        break;
      case 403:
        this.stateService.gotForbiddenError(res);
        break;
    }
  }

  /////////////////////////
  // Debug
  debugGrpc(moduleName: string) {
    switch (moduleName) {
      case 'fastlane':
        this.fastLaneManager.toggleDebugMode(true);
        break;
    }
  }

  changeDebugLevel(level: string) {
    this.fastLaneManager.changeDebugLevel(level);
  }

  public searchStream(query: StreamQuery): Observable<SlowLaneDataRowMessage[]> {
    return this.grpcClient.searchStream(query);
  }

  public slowLaneQueryData(projectId: string, query: string): Observable<any> {
    return this.grpcClient.slowLaneQueryData(projectId, query);
  }

  //////////////////////
  // Fast Lane Methods

  public subscribeV2(
    projectId: string,
    callsigns: string[],
    sources: string[],
    fastLaneManager?: FastLaneManager,
    scope?: string,
  ): Observable<IRocosTelemetryMessage> {
    return this.sdk.client
      .getTelemetryService()
      .subscribe({
        projectId,
        callsigns,
        sources,
        scope,
      })
      .pipe(this.telemetrySink.tap());
  }

  public subscribe(
    projectId: string,
    callsigns: string[],
    sources: string[],
    fastLaneManager = this.fastLaneManager,
    scope = undefined,
  ): SubscribeResult {
    return {
      observable: this.subscribeV2(projectId, callsigns, sources, fastLaneManager, scope),
    };
  }

  public unsubscribe(subscribeResult: SubscribeResult) {
    if (subscribeResult.subscription) {
      subscribeResult.subscription.unsubscribe();
    }
    if (subscribeResult.subStatusSubscription) {
      subscribeResult.subStatusSubscription.unsubscribe();
    }
    let fastLaneManager = subscribeResult.fastLaneManager;
    if (!fastLaneManager) {
      fastLaneManager = this.fastLaneManager;
    }
    fastLaneManager.unsubscribe(subscribeResult.uniqueId, subscribeResult.instanceId);
  }

  public getDebugInfo() {
    return this.grpcClient.getDebugInfo();
  }

  ////////////////////////////////////////////////
  // Robot Controller
  public sendCommand(
    projectId: string,
    callsign: string,
    destination: string,
    payload: string,
    meta?: {
      [key: string]: string;
    },
  ) {
    const command = {
      projectId,
      callsign,
      destination,
      payload,
      meta,
    };

    this.robotController.sendCommand(command);
  }

  /**
   * Send command and keep a stream of acknowledgements
   *
   * @param projectId Project Id
   * @param callsign Robot's Callsign
   * @param destination Target Destination
   * @param payload Payload
   */
  public sendCommandWithAck(
    projectId: string,
    callsign: string,
    destination: string,
    payload: string,
    meta?: {
      [key: string]: string;
    },
  ): Observable<ControlAckMessage> {
    return this.robotController.sendCommandWithAck({
      projectId,
      callsign,
      destination,
      payload,
      meta,
    });
  }

  ////////////////////////////////////////////////
  // Remove File Access

  public listFolder(
    projectId: string,
    callsign: string,
    path: string,
  ): Observable<{
    list: any[];
    type: FileResourceType;
  }> {
    return this.fileAccessor.listFolder(projectId, callsign, path).pipe(
      map((results) => {
        let list = [];
        let type = FileResourceType.DIRINFO;

        if (results?.length > 0) {
          const first = results[0];

          type = first.type as any;

          list = first.data ? JSON.parse(first.data) : [];
        }

        return {
          list,
          type,
        };
      }),
    );
  }

  public downloadFile(projectId: string, callsign: string, path: string): Observable<any> {
    return this.fileAccessor.downloadFile(projectId, callsign, path).pipe(
      mergeMap((results) => {
        if (results?.length > 0) {
          const first = results[0];

          const data = first.data;

          if (data) {
            const json = JSON.parse(data);

            if (json?.Data !== undefined) {
              // Data should be base64 format
              return this.base64ToBlob(json.Data);
            }
          }
        }

        return new Observable<any>();
      }),
    );
  }

  public getDownloadFileUrl(projectId: string, callsign: string, path: string): Observable<string> {
    return this.fileAccessor.getDownloadFileLinkByUsingBlob(projectId, callsign, path);
  }

  public uploadFile(projectId: string, callsign: string, path: string, file: File): Observable<OpResultMessage> {
    return this.fileAccessor.uploadFile(projectId, callsign, path, file);
  }

  public getRobotsForProfile(projectId: string, defId: string): Observable<RocosResponse<any>> {
    return this.rocosClient.robot.robotListForDefs(projectId, defId);
  }

  public deleteRobotProfile(projectId: string, defId: string): Observable<RocosResponse<any>> {
    return this.rocosClient.robot.robotDefsDeleteOne(projectId, defId);
  }

  private base64ToBlob(base64: string, type = 'application/octet-stream') {
    return from(fetch(`data:${type};base64,${base64}`).then((res) => res.blob()));
  }

  ////////////////////////////////////////////////
  // Others
  private publicSDKToWindow() {
    if (!window?.['rocos']) window['rocos'] = {};
    window['rocos'][rocosJsVariableName] = this;
  }

  private getResultFromServiceCallerChunks(chunksList) {
    const results = [];

    for (const chunkItem of chunksList) {
      const uid = chunkItem.uid.hash;
      let cachedChunks: any[] = this.cachedChunksDict[uid];
      if (!cachedChunks) {
        cachedChunks = [];
        this.cachedChunksDict[uid] = cachedChunks;
      }

      cachedChunks.push(chunkItem);

      if (chunkItem.chunkcount === cachedChunks.length) {
        delete this.cachedChunksDict[uid];
        const result = this.getResultFromChunks(cachedChunks);
        results.push(result);
      }
    }

    return results;
  }

  private getResultFromChunks(cachedChunks) {
    const result: any = {};
    cachedChunks.sort((a, b) => {
      return a.chunkindex - b.chunkindex;
    });

    const fullPayload = cachedChunks.map((x) => atob(x.payload)).join('');

    if (fullPayload) {
      const payloadObject = JSON.parse(fullPayload);
      if (cachedChunks[0].uid) {
        result.uid = cachedChunks[0].uid;
      }
      result.payload = payloadObject;
      if (cachedChunks[0].header?.created) {
        result.createdTime = cachedChunks[0].header.created * 1000;
      }
    }
    return result;
  }

  private getResultFromServiceCallerResponseInternal(res) {
    if (res.serviceCallerResponse?.responsesList) {
      const results = [];

      res.serviceCallerResponse.responsesList.forEach((rl) => {
        const result: any = {};

        if (rl.uid) {
          result.uid = rl.uid;
        }

        if (rl.pb_return?.payload) {
          const payloadText = atob(rl.pb_return.payload as string);
          result.payload = JSON.parse(payloadText);
          if (rl.pb_return.header?.created) {
            result.createdTime = rl.pb_return.header.created * 1000;
          }
        }

        if (rl.ack) {
          result.ack = rl.ack;
        }

        if (rl.result) {
          result.result = rl.result;
        }

        results.push(result);
      });

      return results;
    }
    return null;
  }
}
