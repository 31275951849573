import type { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import type { GamepadButtonConfig, ButtonDef } from '../../models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class GamepadButtonVariableDialogData {
  model: any;
  sources: ButtonDef[];
}

@Component({
  selector: 'app-gamepad-button-variable-dialog',
  templateUrl: './gamepad-button-variable-dialog.component.html',
  styleUrls: ['./gamepad-button-variable-dialog.component.scss'],
})
export class GamepadButtonVariableDialogComponent implements OnInit {
  form: UntypedFormGroup = new UntypedFormGroup({});
  model: GamepadButtonConfig;
  sources: ButtonDef[];

  constructor(
    public dialogRef: MatDialogRef<GamepadButtonVariableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: GamepadButtonVariableDialogData,
  ) {
    if (data) {
      if (data.model) {
        this.model = data.model;
      }
      if (data.sources) {
        this.sources = data.sources;
      }
    }
  }

  ngOnInit() {
    this.createForm(this.model);
  }

  onSubmit() {
    if (!this.form.valid) {
      this.form.markAsDirty();
      return;
    }

    const data = this.form.value;
    data.source = {
      type: data.source,
    };

    this.dialogRef.close(data);
  }

  onCancel() {
    this.dialogRef.close();
  }

  setSources(sources: ButtonDef[]) {
    this.sources = sources;
  }

  createForm(model?: GamepadButtonConfig) {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(model?.name ? model.name : '', [Validators.required]),
      source: new UntypedFormControl(model?.source ? model.source.type : null, [Validators.required]),
      value: new UntypedFormControl(model?.value ? model.value : '$value', [Validators.required]),
      defaultValue: new UntypedFormControl(model?.defaultValue ? model.defaultValue : '0', [Validators.required]),
    });
  }
}
