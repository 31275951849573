<ng-container *transloco="let tCommon; read: 'common'">
  <ng-container *transloco="let tNavBar; read: 'core.navBar'">
    <mat-toolbar color="primary" class="navbar rocos-dark-theme">
      <span class="first-column" [class.no-hamburger]="sidebarStatus === 'force-hide'">
        <app-navbar-logo></app-navbar-logo>

        <app-breadcrumb></app-breadcrumb>
      </span>
      <div class="right-content">
        <div class="left-place-holder">
          <!-- Place holder for making search box centered-->
        </div>

        <div class="right-buttons">
          <app-navbar-connection></app-navbar-connection>

          <!-- Project Picker -->
          <div>
            <app-project-picker></app-project-picker>
          </div>

          <!-- DD link -->
          <app-navbar-dd-link></app-navbar-dd-link>

          <!-- User item -->
          <div class="navbar-user-item">
            <app-navbar-user-item></app-navbar-user-item>
          </div>

          <app-courier-wrapper *ngIf="courierLoaded$ | async"></app-courier-wrapper>

          <!-- More info icon button -->
          <button
            mat-icon-button
            matTooltip="{{ tCommon('more') }}"
            [matMenuTriggerFor]="moreInfoMenu"
            tabindex="-1"
            appTestID="more-btn"
          >
            <span class="ri-more icon-button grey-icon d-inline-block"></span>
          </button>
          <mat-menu #moreInfoMenu="matMenu">
            <button mat-menu-item (click)="onInvitation()" *appLegacyUsersOnly="true">
              <span class="label">
                {{ tCommon('invitations') }}
              </span>
            </button>
            <button mat-menu-item (click)="onDocumentationLink()" appTestID="more-menu-doc-link">
              <span class="label">
                {{ tCommon('documentation') }}
              </span>
              <span class="icon">
                <span class="ri-external-link"></span>
              </span>
            </button>
            <a mat-menu-item href="mailto:support@dronedeploy.com">
              <span class="label"> Support </span>
              <span class="icon">
                <span class="ri-external-link"></span>
              </span>
            </a>
            <button *appDeveloping mat-menu-item (click)="onDevDebug()">
              <span class="label">
                {{ tCommon('devDebug') }}
              </span>
              <span class="icon">
                <span class="ri-object"></span>
              </span>
            </button>
            <hr class="separator" />
            <button mat-menu-item (click)="onSignOut()">
              <span class="label">
                {{ tCommon('signOut') }}
              </span>
              <span class="icon">
                <span class="ri-sign-out"></span>
              </span>
            </button>

            <!-- Admin Only -->
            <ng-container *ngIf="isAdmin">
              <hr class="separator" />
              <button mat-menu-item (click)="onAdminShowHide()">
                <ng-container *ngIf="isHideAdminOnlyContent">
                  <span class="label">
                    {{ tNavBar('adminOnlyShow') }}
                  </span>
                  <span class="icon">
                    <span class="ri-hidden"></span>
                  </span>
                </ng-container>
                <ng-container *ngIf="!isHideAdminOnlyContent">
                  <span class="label">
                    {{ tNavBar('adminOnlyHide') }}
                  </span>
                  <span class="icon">
                    <span class="ri-view"></span>
                  </span>
                </ng-container>
              </button>
            </ng-container>
          </mat-menu>

          <!-- Development controls -->
          <button
            mat-icon-button
            matTooltip="{{ tCommon('developing') }}"
            [matMenuTriggerFor]="devMenu"
            tabindex="-1"
            *ngIf="!isProd"
          >
            <span
              class="ri-access icon-button d-inline-block"
              [ngClass]="{ 'red-icon': !isDevHideDevelopingContent, 'green-icon': isDevHideDevelopingContent }"
            ></span>
          </button>
          <mat-menu #devMenu="matMenu">
            <button mat-menu-item (click)="onDevShowHide()">
              <span *ngIf="isDevHideDevelopingContent">
                <span class="ri-hidden mr-2"></span>
                <span>
                  {{ tNavBar('developingShow') }}
                </span>
              </span>
              <span *ngIf="!isDevHideDevelopingContent">
                <span class="ri-view mr-2"></span>
                <span>
                  {{ tNavBar('developingHide') }}
                </span>
              </span>
            </button>
            <button mat-menu-item (click)="onExperimentalShowHide()">
              <span *ngIf="isHideExperimentalContent">
                <span class="ri-hidden mr-2"> </span>
                <span>
                  {{ tNavBar('experimentalShow') }}
                </span>
              </span>
              <span *ngIf="!isHideExperimentalContent">
                <span class="ri-view mr-2"></span>
                <span>
                  {{ tNavBar('experimentalHide') }}
                </span>
              </span>
            </button>
            <button mat-menu-item (click)="onDevHighlight()">
              <span *ngIf="!isDevHighlight">
                <span class="ri-hidden mr-2"> </span>
                <span>
                  {{ tNavBar('highlightEnable') }}
                </span>
              </span>
              <span *ngIf="isDevHighlight">
                <span class="ri-view mr-2"></span>
                <span>
                  {{ tNavBar('highlightDisable') }}
                </span>
              </span>
            </button>

            <hr class="separator" />

            <button mat-menu-item (click)="onAdminShowHide()">
              <span *ngIf="isHideAdminOnlyContent">
                <span class="ri-hidden mr-2"></span>
                <span>
                  {{ tNavBar('adminOnlyShow') }}
                </span>
              </span>
              <span *ngIf="!isHideAdminOnlyContent">
                <span class="ri-view mr-2"></span>
                <span>
                  {{ tNavBar('adminOnlyHide') }}
                </span>
              </span>
            </button>
          </mat-menu>
        </div>
      </div>
    </mat-toolbar>
  </ng-container>
</ng-container>
