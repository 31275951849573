import type { OnChanges, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import type { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { first } from 'rxjs/operators';
import { DialogService } from '../../dialogs';
import type { ButtonDef, ButtonDefWithValue } from '../../models';
import { GamepadButtonConfig } from '../../models';
import { GamepadButtonVariableDialogComponent } from '../gamepad-button-variable-dialog';

@Component({
  selector: 'app-gamepad-button-config',
  templateUrl: './gamepad-button-config.component.html',
  styleUrls: ['../shared-styles.scss', './gamepad-button-config.component.scss'],
})
export class GamepadButtonConfigComponent implements OnChanges {
  @ViewChild('variablesTable')
  variablesTable: MatTable<ButtonDef>;

  @Input()
  sources: ButtonDef[] = [];

  @Input()
  pressedValues: ButtonDefWithValue[] = [];

  @Input()
  variables: GamepadButtonConfig[] = [];

  @Output()
  variablesChange = new EventEmitter<GamepadButtonConfig[]>();

  public pressedButtonsObject: {
    [name: string]: ButtonDefWithValue;
  } = {};

  get json(): any {
    if (this.variables) {
      return this.variables.map((item) => {
        return item.toJSON();
      });
    }
  }

  public displayedColumns: string[] = ['name', 'source', 'value', 'defaultValue', 'editing'];

  public newVariableForm: UntypedFormGroup;

  dialogRef: MatDialogRef<GamepadButtonVariableDialogComponent>;

  constructor(private dialog: MatDialog, private dialogService: DialogService) {}

  ngOnChanges(changes: SimpleChanges) {
    const obj = {};
    if (changes['pressedValues'] && this.pressedValues) {
      this.pressedValues.forEach((value) => {
        obj[value.type] = value;
      });
    }
    if (changes['sources'] && this.dialogRef?.componentInstance) {
      // Update sources to current opening dialog if there are any
      this.dialogRef.componentInstance.setSources(this.sources);
    }

    this.pressedButtonsObject = obj;
  }

  onAddNew() {
    this.dialogRef = this.dialog.open(GamepadButtonVariableDialogComponent, {
      data: {
        sources: this.sources,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const newOne = GamepadButtonConfig.fromJSON(result);

        this.variables = [...this.variables, newOne];

        this.variablesChanged();
      }
    });
  }

  onEdit(item: GamepadButtonConfig, index: number) {
    this.dialogRef = this.dialog.open(GamepadButtonVariableDialogComponent, {
      data: {
        model: item,
        sources: this.sources,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const model = GamepadButtonConfig.fromJSON(result);
        if (this.variables) {
          this.variables.splice(index, 1, model);

          this.variablesTable.renderRows();

          this.variablesChanged();
        }
      }
    });
  }

  onRemove(item: GamepadButtonConfig, index: number) {
    const title = 'Delete Variable?';
    const message = '<p>Are you sure you want to delete this variable?</p>';

    this.dialogService
      .deleteConfirm(title, message)
      .pipe(first())
      .subscribe((confirmed) => {
        if (confirmed) {
          this.variables.splice(index, 1);

          this.variablesTable.renderRows();

          this.variablesChanged();
        }
      });
  }

  onAddVariable() {
    this.newVariableForm = new UntypedFormGroup({
      'name': new UntypedFormControl('', [Validators.required]),
      'value': new UntypedFormControl('$value', [Validators.required]),
      'defaultValue': new UntypedFormControl('0', [Validators.required]),
      'source': new UntypedFormControl(null, [Validators.required]),
    });
  }

  onSubmitAddVariableForm() {
    if (!this.newVariableForm.valid) {
      this.newVariableForm.markAsDirty();
      return;
    }
    const value = this.newVariableForm.value;

    this.variables.push(new GamepadButtonConfig(value.name, value.source, value.value));
    this.variablesTable.renderRows();

    this.newVariableForm = null;
  }

  onCancelAddVariable() {
    this.newVariableForm = null;
  }

  private _loadDemoData() {
    const json = [
      {
        name: 'varSafetyStop',
        source: {
          type: 16,
        },
        value: '$value',
      },
      {
        name: 'forwardBack',
        source: {
          type: 6,
        },
        value: '$value',
      },
      {
        name: 'twist',
        source: {
          type: 5,
        },
        value: '$value * -1',
      },
    ];

    const variables: GamepadButtonConfig[] = GamepadButtonConfig.fromJSONToList(json);

    this.variables = variables;
    this.variablesChanged();
  }

  private variablesChanged() {
    this.variablesChange.next(this.variables);
  }
}
