import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import type { Message } from '@shared/data-explorer/message-preview';

@Component({
  selector: 'app-send-message-dialog',
  templateUrl: './send-message-dialog.component.html',
  styleUrls: ['./send-message-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendMessageDialogComponent {
  protected form = new FormGroup({
    target: new FormControl('', [Validators.required]),
    payload: new FormControl<string | undefined>(undefined),
    metadata: new FormControl<string | undefined>(undefined),
  });

  constructor(private dialogRef: MatDialogRef<SendMessageDialogComponent, Message>) {}

  protected onSubmit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.dialogRef.close(this.form.value as Message);
  }

  protected onCancel(): void {
    this.dialogRef.close();
  }
}
