import type { AbstractControl } from '@angular/forms';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import type { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { FormUtils } from '@shared/utils/form-utils';
import type { Widget } from '../../../models';

@Component({
  selector: 'app-add-javascript-dialog',
  templateUrl: './add-javascript-dialog.component.html',
  styleUrls: ['./add-javascript-dialog.component.scss'],
})
export class AddJavascriptDialogComponent implements OnInit {
  public isLoading: boolean = false;

  public form: UntypedFormGroup;
  public mode: string = 'add';
  public projectId: string;
  public widget: Widget;

  public get javascriptName(): AbstractControl {
    return FormUtils.getControl(this.form, 'javascriptName');
  }

  public constructor(
    public dialogRef: MatDialogRef<AddJavascriptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddJavascriptDialogData,
  ) {
    if (data?.mode) {
      this.mode = data.mode;
    }

    if (data?.widget) {
      this.widget = data.widget;
    }

    if (data) {
      this.projectId = this.data.projectId;
    }
  }

  public ngOnInit(): void {
    this.createForm();
  }

  public onCancel(): void {
    if (!this.isLoading) {
      this.dialogRef.close();
    }
  }

  public onSubmit(): void {
    if (!this.form.valid) {
      this.form.markAsDirty();
      return;
    }

    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.dialogRef.disableClose = true;

    const model = this.saveModel();
    this.dialogRef.close(model.name);
  }

  private saveModel() {
    const model: any = {};
    model.name = this.form.value.javascriptName;
    return model;
  }

  private createForm() {
    let javascriptName = '';

    if (this.widget) {
      javascriptName = this.widget.widgetHeading;
    }

    this.form = new UntypedFormGroup({
      javascriptName: new UntypedFormControl(javascriptName, [Validators.required]),
    });
  }
}

export class AddJavascriptDialogData {
  public projectId: string;
  public mode?: string;
  public widget?: Widget;
}
