import type { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isEmptyInputValue } from './shared';

// Ref: https://stackoverflow.com/a/2063247/4288118
const CALLSIGN_REGEXP = /^(?!\d+$)(?!.*-$)(?!-)[a-z\d-]{1,63}$/;
const VIRTUAL_CALLSIGN_REGEXP = /^[a-z](?:[a-z\d-]{0,61}[a-z\d])?$/; // K8s deployment requires stricter rules
const RESERVED_CALLSIGNS_REGEXP = /^(?!(cloud|rocos|dronedeploy|dd|virtual|physical)$).*$/;
const COMMAND_ID_REGEXP = /^[\w/.-]{1,40}$/;
const EVENT_ID_REGEXP = /^[\w/.-]{1,40}$/;
const SCHEDULE_ID_REGEXP = /^[\w/.-]{1,40}$/;
const FLOW_ID_REGEXP = /^[\da-z-]{3,}$/;
const MAP_ID_REGEXP = /^[\da-z]([\da-z-]*[\da-z])?$/;
const FILENAME_REGEXP = /^[a-z\d-]+\.[a-z]{3,4}$/;

export class IdValidator {
  public static id(control: AbstractControl, pattern: RegExp, name: string): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }

    const result: Record<string, boolean> = {};
    result[name] = true;

    return pattern.test(control.value) ? null : result;
  }

  public static callsign(control: AbstractControl): ValidationErrors | null {
    return IdValidator.id(control, CALLSIGN_REGEXP, 'callsign');
  }

  public static virtualCallsign(control: AbstractControl): ValidationErrors | null {
    return IdValidator.id(control, VIRTUAL_CALLSIGN_REGEXP, 'virtualCallsign');
  }

  public static reservedCallsigns(control: AbstractControl): ValidationErrors | null {
    return IdValidator.id(control, RESERVED_CALLSIGNS_REGEXP, 'reservedCallsigns');
  }

  public static commandId(control: AbstractControl): ValidationErrors | null {
    return IdValidator.id(control, COMMAND_ID_REGEXP, 'commandId');
  }

  public static eventId(control: AbstractControl): ValidationErrors | null {
    return IdValidator.id(control, EVENT_ID_REGEXP, 'eventId');
  }

  public static scheduleId(control: AbstractControl): ValidationErrors | null {
    return IdValidator.id(control, SCHEDULE_ID_REGEXP, 'scheduleId');
  }

  public static flowId(control: AbstractControl): ValidationErrors | null {
    return IdValidator.id(control, FLOW_ID_REGEXP, 'flowId');
  }

  public static filename(control: AbstractControl): ValidationErrors | null {
    return IdValidator.id(control, FILENAME_REGEXP, 'filename');
  }

  public static map(control: AbstractControl): ValidationErrors | null {
    return IdValidator.id(control, MAP_ID_REGEXP, 'map');
  }

  public static noDuplicatesWithList(list: string[], errorKey?: string): ValidatorFn {
    return this.noDuplicates(() => list, errorKey);
  }

  public static noDuplicates(getExisting: () => string[], errorKey: string = 'duplicated'): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      const list = getExisting();
      if (list?.length > 0) {
        return list.includes(control.value) ? { [errorKey]: true } : null;
      }
      return null;
    };
  }
}
