export class TokenNotFoundError extends Error {
  constructor(token: string) {
    super(`Invalid reference token: ${token}`);
  }
}

export const resolve = (obj: unknown, pointer: string) => {
  const tokens = Array.isArray(pointer) ? pointer : parse(pointer);

  for (const token of tokens) {
    if (!(typeof obj == 'object' && token in obj)) {
      throw new TokenNotFoundError(token);
    }

    obj = obj[token];
  }

  return obj;
};

const parse = (pointer: string) => {
  if (pointer === '') {
    return [];
  }

  if (pointer.charAt(0) !== '/') {
    throw new Error('Invalid JSON pointer: ' + pointer);
  }

  return pointer
    .substring(1)
    .split('/')
    .map((element) => unescapeElement(element));
};

const unescapeElement = (str: string) => {
  return str.replace(/~1/g, '/').replace(/~0/g, '~');
};
