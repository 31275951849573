import type { Config, UrlsConfig } from './base';
import { defaultEnvironment, defaultUrls } from './default';
import { environment as prodEnvironment } from './environment.prod';
export const environment: Config = {
  ...prodEnvironment,
  name: 'dev',
  courier: {
    clientKey: 'ZjUxMmQ5OGYtY2UxZC00Mjg0LWIwNTktOTRkZWI2OTIzZTc1L3Rlc3Q=',
  },
  datadog: {
    ...prodEnvironment.datadog,
    env: 'dev',
    sessionReplaySampleRate: 20,
  },
  fullyQualifiedLoginRedirectsEnabled: true,
  testHooksEnabled: true,
  api: {
    url: 'https://dev-api-automate.dronedeploy.com',
    grpcUrl: 'https://dev-api-automate.dronedeploy.com',
  },
  i18n: {
    transloco: {
      availableLangs: ['en', 'zh-CN'],
      defaultLang: 'en',
      fallbackLang: 'en',
    },
    autoDetectLanguageAndSwitch: true,
  },
  links: {
    ...defaultEnvironment.links,
    dd: 'https://test.dronedeploy.com',
  },
  webComponents: {
    location: 'https://dev-components-automate.dronedeploy.com/web-components.js',
  },
  droneDeploy: {
    thumbnailApi: 'https://tiles_2_rt_test.dronedeploy.com/images/thumb/',
    tilesApi: 'https://tiles_2_rt_test.dronedeploy.com/unsafe/smart/',
  },
  launchDarkly: {
    clientId: '5a1f047a32fd110d47ea1f46',
  },
  djiDock: {
    mqtt: {
      protocol: 'mqtts://',
      host: 'dji-dock-test-us.dronedeploy.com',
      port: '8883',
    },
    stream: {
      url: 'rtmps://rtmp-test-us.dronedeploy.com',
      port: '1937',
    },
    objectStorage: {
      protocol: 'https://',
      host: 's3-test-us.dronedeploy.com',
    },
  },
};

export const urls: UrlsConfig = defaultUrls;
