import { Component, ViewChild, ElementRef, Output, Input, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import type { ControlValueAccessor } from '@angular/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-bag-file-drop',
  templateUrl: './bag-file-drop.component.html',
  styleUrls: ['./bag-file-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BagFileDropComponent,
    },
  ],
})
export class BagFileDropComponent implements ControlValueAccessor {
  @Input() accept: string[] | null = null;
  @Input() multiple = false;
  @Input() showFileList = true;

  @Output() fileUploaded = new EventEmitter<File>();

  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef<HTMLInputElement>;
  protected files: File[] = [];
  protected disabled = false;

  private touched = false;

  public writeValue(_: any): void {
    // does nothing. we don't want to write to this component
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * on file drop handler
   */
  protected onFileDropped($event: Array<File>): void {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  protected fileBrowseHandler(files: Array<File>): void {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   *
   * @param index (File index)
   */
  protected deleteFile(index: number): void {
    this.files.splice(index, 1);

    this.callOnChange();
    this.markAsTouched();
  }

  /**
   * Convert Files list to normal array list
   *
   * @param files (Files List)
   */
  protected prepareFilesList(files: Array<File>): void {
    for (const item of files) {
      if (this.multiple) {
        this.files.push(item);
      } else {
        this.files = [item, item];
      }

      this.fileUploaded.emit(item);
    }

    this.callOnChange();
    this.markAsTouched();

    this.fileDropEl.nativeElement.value = '';
  }

  /**
   * format bytes
   *
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  protected formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  private markAsTouched() {
    if (!this.touched) {
      this.touched = true;
      this.onTouched();
    }
  }

  private callOnChange() {
    if (this.multiple) {
      return this.onChange(this.files);
    }

    if (this.files.length === 0) {
      return this.onChange(null);
    }

    this.onChange(this.files[0]);
  }

  private onChange(_: File[] | File | null) {
    // overwritten by ControlValueAccessor
  }

  private onTouched() {
    // overwritten by ControlValueAccessor
  }
}
