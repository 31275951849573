import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DialogService } from '../../dialogs';
import type { TreeItem } from '../../models';
import type { Robot } from '../../models';
import { DataSourcesTreeComponent } from '../data-sources-tree';
import { SendMessageDialogComponent } from '@shared/data-explorer/send-message-dialog';
import { MatDialog } from '@angular/material/dialog';
import type { Message } from '@shared/data-explorer/message-preview';
import { FeatureFlags } from '@shared/services/feature-flag/feature-flags';

export class DataExplorerResult {
  callsign: string;
  treeItem: TreeItem;
}

@Component({
  selector: 'app-data-explorer-wrapper',
  templateUrl: './data-explorer-wrapper.component.html',
  styleUrls: ['./data-explorer-wrapper.component.scss'],
})
export class DataExplorerWrapperComponent implements OnInit {
  @Input()
  public callsign: string;

  @Input()
  public projectId: string;

  @Input()
  public showServiceMode: boolean = false;

  @Input()
  public insertDataURIMode: boolean = true;

  @Input()
  public insertSourceOnly: boolean = false;

  /**
   * Data Stream Mode: Without robot's callsign, show tree for data stream.
   */
  @Input()
  public dataStreamMode: boolean = false;

  @Input()
  public showWarningMessageBeforeInserting: boolean = false;

  /**
   * Display the robot picker?
   */
  @Input()
  public enableRobotPicker: boolean = false;

  @Input()
  public robotsForPicker: Robot[] = [];

  @Output()
  public dataURIChange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public dataItemChange: EventEmitter<TreeItem> = new EventEmitter<TreeItem>();

  @Output()
  public callsignChange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public dataChange: EventEmitter<DataExplorerResult> = new EventEmitter<DataExplorerResult>();

  @ViewChild('sourcesTree')
  public sourcesTree: DataSourcesTreeComponent;

  public showServices: UntypedFormControl;
  public showMessages: UntypedFormControl;

  public isPausing: boolean = false;
  public selectedSources: TreeItem[] = [];
  public sentMessages: Message[] = [];

  public currentTreeComponentIndex = 1;
  showRobotSearch: boolean;
  inputCallsign: string;

  protected readonly FeatureFlags = FeatureFlags;

  constructor(private dialogService: DialogService, private dialog: MatDialog) {}

  ngOnInit() {
    this.inputCallsign = this.callsign;
    this.showRobotSearch = this.enableRobotPicker || (this.dataStreamMode && !this.inputCallsign);
    this.showMessages = new UntypedFormControl();
    this.showServices = new UntypedFormControl();
    this.showMessages.setValue(true);
    if (this.showServiceMode) {
      this.showServices.setValue(true);
    }
  }

  public onTreeSelectionChange(list: TreeItem[]) {
    this.selectedSources = list;
  }

  public onPauseToggle() {
    this.isPausing = !this.isPausing;
  }

  public onClearAllSelection() {
    if (this.sourcesTree) {
      this.sourcesTree.clearAllSelection();
    }

    this.sentMessages = [];
  }

  public onDataItemChange(treeItem: TreeItem) {
    const showWarning: boolean = this.showWarningMessageBeforeInserting;

    const emitFunc = () => {
      this.dataItemChange.next(treeItem);

      this.dataURIChange.next(treeItem.path);

      this.dataChange.next({
        callsign: this.callsign,
        treeItem,
      });
    };

    if (showWarning) {
      // Show warning message
      const title = 'Warning';
      const message = `<p>
        Inserting this property will reset the contents of the value field and you will lose any changes you've made.
        </p>`;
      this.dialogService.confirm(title, message, 'Continue').subscribe((confirmed) => {
        if (confirmed) {
          emitFunc();
        }
      });
    } else {
      // Directly emit.
      emitFunc();
    }
  }

  public onClosePreview(item: TreeItem) {
    item.unselect();
  }

  public onCloseMessage(item: number) {
    this.sentMessages.splice(item, 1);
  }

  public onGetCallablesFailed() {
    this.showServiceMode = false;
    this.showServices.setValue(false);
  }

  public onRefreshTree() {
    // Remove all selection btw
    this.onClearAllSelection();

    this.currentTreeComponentIndex = this.currentTreeComponentIndex === 1 ? 0 : 1;
  }

  public checkBoxChanged() {
    if (!this.showMessages.value && !this.showServices.value) {
      this.showMessages.setValue(true);
    }

    this.onRefreshTree();
  }

  public onCallsignSelected(callsign: string) {
    // When robot's callsign changed, refresh the whole tree component
    this.onRefreshTree();

    this.callsign = callsign;

    this.callsignChange.emit(callsign);
  }

  public onRobotCountUpdated(_count: number) {
    // Will not this method right now. but will display some messages when there is no robots.
  }

  protected openSendMessageDialog() {
    const dialogRef = this.dialog.open<SendMessageDialogComponent, never, Message>(SendMessageDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.sentMessages.push(result);
      }
    });
  }
}
