import { Injectable } from '@angular/core';
import type { Subscriber } from 'rxjs';
import { interval, Observable } from 'rxjs';
import { distinctUntilChanged, finalize, first, map, withLatestFrom } from 'rxjs/operators';
import type { RobotTemplate, RocosTelemetryMessage } from '@team-rocos/rocos-js';
import { gRPCSource } from '@team-rocos/rocos-js';
import type { AgentVersion } from '../../models';
import {
  Robot,
  RobotDefinition,
  RobotDeploymentResult,
  RobotTypeOption,
  ScreenButtonDef,
  ScreenButtonActionDef,
  ScreenControlPayloadDef,
  ScreenControlValue,
  GamepadButtonTriggerType,
} from '../../models';
import { RocosClientService } from '../rocos-client';
import { IHeartbeatStatus, Heartbeat } from '../../models/robot/heartbeat';
import { RocosSdkClientService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class RobotService {
  constructor(private rocosClientService: RocosClientService, private sdk: RocosSdkClientService) {}

  /** Get all robots in a project
   *
   * @param projectId
   */
  public list(projectId: string): Promise<Robot[]> {
    return this.sdk.client
      .getRobotService()
      .getRobots(projectId)
      .then((res) => {
        return res.map((robot) => {
          return Robot.fromModel(robot);
        });
      });
  }

  /**
   * @deprecated Use list instead
   */
  public list$(projId: string): Observable<Robot[]> {
    return this.rocosClientService.rocosClient.robot.list(projId).pipe(
      map((res) => {
        const data = (res.data as any[]) || [];
        return data.map((robot) => {
          return Robot.fromModel(robot);
        });
      }),
    );
  }

  public info(projId: string, callsign: string): Observable<Robot> {
    return this.rocosClientService.rocosClient.robot.info(projId, callsign).pipe(
      map((res) => {
        const data = res.data as any;
        return Robot.fromModel(data);
      }),
    );
  }

  public addEventDefinitions(projId: string, callsign: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.robot.AddEventDefinitions(projId, callsign, model).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }
  public getAllEventDefinitions(projId: string, callsign: string, includeCounts: number = 0): Observable<any> {
    return this.rocosClientService.rocosClient.robot.getEventDefinitions(projId, callsign, includeCounts).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public getEventHistory(projId: string, callsign: string, eventDefinitionId: string): Observable<any> {
    return this.rocosClientService.rocosClient.robot.getEventHistory(projId, callsign, eventDefinitionId).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public create(projId: string, robot: Robot): Observable<string> {
    return this.rocosClientService.rocosClient.robot.create(projId, robot as any).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  public update(projId: string, robot: Robot): Observable<string> {
    return this.rocosClientService.rocosClient.robot.update(projId, robot as any).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  public remove(projId: string, callsigns: string[]): Observable<string> {
    return this.rocosClientService.rocosClient.robot.delete(projId, callsigns).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public getRobotDefinitions(): Observable<RobotDefinition[]> {
    return this.rocosClientService.rocosClient.robot.templates().pipe(
      map((res) => {
        const data: RobotTemplate[] = res.data || [];

        const templates = data?.map((template) => {
          return RobotDefinition.fromRobotTemplate(template);
        });

        return templates ? templates : [];
      }),
    );
  }

  /**
   * Get virtual robot deployment details.
   *
   * @param projectId Project Id
   * @param callsign Callsign
   */
  public virtualRobotDeploymentDetails(projectId: string, callsign: string): Observable<RobotDeploymentResult> {
    return this.rocosClientService.rocosClient.robot.virtualRobotDeploymentDetails(projectId, callsign).pipe(
      map((res) => {
        const data = res.data;
        return RobotDeploymentResult.fromModel(data);
      }),
    );
  }

  /**
   * Deploy virtual robot by project id and callsign
   */
  public deployVirtualRobot(projectId: string, callsign: string): Observable<any> {
    return this.rocosClientService.rocosClient.robot.deployVirtualRobot(projectId, callsign).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  /**
   * Get JSON schema for data sources tree component.
   */
  public dataSourcesJsonSchema(): Observable<any> {
    return this.rocosClientService.rocosClient.robot.dataSourcesSchema().pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  /**
   * Get robot type options array.
   */
  public robotTypeOptions(): Observable<RobotTypeOption[]> {
    return new Observable((observer) => {
      const options: RobotTypeOption[] = [];

      options.push(RobotTypeOption.default('virtualRos'));
      options.push(RobotTypeOption.default('ros-turtle3'));

      observer.next(options);
      observer.complete();
    });
  }

  /**
   * Get robot type options array.
   */
  public screenControlsList(): Observable<ScreenButtonDef[]> {
    return new Observable((observer) => {
      const list: ScreenButtonDef[] = [];

      list.push(
        new ScreenButtonDef('varUxLeft', 'Left', RobotTypeOption.default('virtualRos'), 'button', 'Turn robot left', [
          new ScreenControlValue(0, 'Turn Left', null),
          new ScreenControlValue(
            1,
            'Turning Left',
            '{"linear": {"x":0, "y":0, "z":0},"angular": {"x":0, "y":0, "z":1}}',
          ),
        ]),
      );
      list.push(
        new ScreenButtonDef(
          'varUxRight',
          'Right',
          RobotTypeOption.default('virtualRos'),
          'button',
          'Turn robot right',
          [
            new ScreenControlValue(0, 'Turn Right', null),
            new ScreenControlValue(
              1,
              'Turning Right',
              '{"linear": {"x":0, "y":0, "z":0},"angular": {"x":0, "y":0, "z":-1}}',
            ),
          ],
        ),
      );

      observer.next(list);
      observer.complete();
    });
  }

  public screenControlActionsList(): Observable<ScreenButtonActionDef[]> {
    return new Observable((observer) => {
      const list: ScreenButtonActionDef[] = [];

      list.push(new ScreenButtonActionDef('actionLeft', 'btnLeft', 100, GamepadButtonTriggerType.ONCE));
      list.push(new ScreenButtonActionDef('actionRight', 'btnRight', 100, GamepadButtonTriggerType.ONCE));

      observer.next(list);
      observer.complete();
    });
  }

  public screenControlPayloadsList(): Observable<ScreenControlPayloadDef[]> {
    return new Observable((observer) => {
      const list: ScreenControlPayloadDef[] = [];

      list.push(new ScreenControlPayloadDef('actionLeft', '/cmd_vel', `$varUxLeft`));

      observer.next(list);
      observer.complete();
    });
  }

  public getRobotDefinitionDetails(projectId: string, robotDefinitionId: string): Observable<any> {
    const defaultCollectionId = '_default';
    return this.rocosClientService.rocosClient.dashboard.getDocs(projectId, defaultCollectionId).pipe(
      map((res) => {
        const data = res.data as any;
        let projectDef = null;

        if (data?.id && data.docs?.length > 0) {
          projectDef = data.docs[0];
        }

        if (projectDef?.robotDefinitions) {
          const robotDefs = projectDef.robotDefinitions;

          const defsInfo = {};
          robotDefs.forEach((def) => {
            defsInfo[def.id] = def;
          });

          if (defsInfo[robotDefinitionId]) {
            return defsInfo[robotDefinitionId];
          }
        }

        return null;
      }),
    );
  }

  public getAgentVersionInfo(
    projectId: string,
    callsign: string,
    source: string = '/rocos/agent/version',
  ): Observable<AgentVersion> {
    return Observable.create((observer: Subscriber<AgentVersion>) => {
      const sub = this.rocosClientService.subscribe(projectId, [callsign], [source]);

      sub.observable.subscribe((msg) => {
        if (msg?.payload) {
          const info = msg.payload;

          observer.next(info);
        }

        this.rocosClientService.unsubscribe(sub);
        observer.complete();
      });
    });
  }
  public getAgentVersionFromCloud(projectId: string, callsign: string): Observable<string> {
    return this.rocosClientService.rocosClient.robot.attributes(projectId, callsign).pipe(
      first(),
      map((resp) => resp?.data?.['agent.version']),
    );
  }

  /**
   * @description Get Robot Heartbeat, auto-unsubscribe, notify if robot not responding after ${timeOut}ms elapsed
   */
  public getHeartbeat(projectId: string, callsign: string, timeOut = 5000): Observable<Heartbeat> {
    const subRes = this.rocosClientService.subscribe(projectId, [callsign], [gRPCSource.types.heartbeat]);

    return interval(500).pipe(
      withLatestFrom(subRes.observable as Observable<RocosTelemetryMessage>),
      finalize(() => this.rocosClientService.unsubscribe(subRes)),
      map(([, msg]) => {
        const connected = Date.now() - msg.receivedAt.getTime() < timeOut;
        return new Heartbeat(connected ? IHeartbeatStatus.Alive : IHeartbeatStatus.Disconnected);
      }),
      distinctUntilChanged((a, b) => a.status === b.status),
    );
  }
}
