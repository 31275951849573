import { Component } from '@angular/core';
import { UserService, AppTrackingService } from '@shared/services';
import type { ESidebarStatus } from '@shared/services/gui/gui-state.service';
import { GuiStateService } from '@shared/services/gui/gui-state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FeatureFlags } from '@shared/services/feature-flag/feature-flags';

@UntilDestroy()
@Component({
  selector: 'app-console-hosting',
  templateUrl: './console-hosting.component.html',
  styleUrls: ['./console-hosting.component.scss'],
})
export class ConsoleHostingComponent {
  public sidebarStatus: ESidebarStatus;
  public FeatureFlags = FeatureFlags;

  public constructor(userService: UserService, appTrackingService: AppTrackingService, guistate: GuiStateService) {
    guistate.sidebarStatus$.pipe(untilDestroyed(this)).subscribe((status) => {
      this.sidebarStatus = status;
    });

    userService.userUpdated.pipe(untilDestroyed(this)).subscribe((user) => {
      appTrackingService.setUser(user);
    });
  }
}
